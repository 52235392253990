import {AuthState} from "../features/Login/authSlice";
import {Services} from "../types/services";
import {isNumber} from "util";

export const servicesEnumKeys = Object.keys(Services).filter(key => isNaN(Number(key)));
export const servicesEnumKeysNumbers = Object
    .keys(Services)
    .map(k => Number(k))
    .filter(key => Number.isInteger(key))
;

export function todayDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
}

export function formatPhoneAfterMask(phone: string) {
    return phone.trim()
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll(')', '')
        .replaceAll('(', '')
        // clear mask for validation
        .replaceAll('_', '')
        .replaceAll('-', '')
}

async function getValueFromStorage(key: string) {
    return localStorage.getItem(key);
}

async function saveToStorage(key: string, value: string) {
    return localStorage.setItem(key, value);
}

const STORAGE_AUTH_SESSION = 'auth_session';
const STORAGE_PHONE_NUMBER = 'store_phone_number';
export async function getAuthStore() {
    const authenticationStore = await getValueFromStorage(STORAGE_AUTH_SESSION);

    if (!authenticationStore) {
        return null;
    }

    return JSON.parse(authenticationStore);
}

export async function saveAuthStore(authenticationStore: AuthState) {
    await saveToStorage(STORAGE_AUTH_SESSION, JSON.stringify(authenticationStore));
}

export async function getPhoneNumberFromStore() {
    const store = await getValueFromStorage(STORAGE_PHONE_NUMBER);

    return store;
}

export async function savePhoneNumberToStore(number: string) {
    await saveToStorage(STORAGE_PHONE_NUMBER, number);
}
