import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../helpers/validation';

export type ValidationKeys = 'code' | 'discountAmount' | 'expirationDate' | 'services';

export const rulesPromoCodesForm: RulesForFieldsType<ValidationKeys> = {
  code: {
    userFriendlyFieldName: 'Код',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 64,
  },
  discountAmount: {
    userFriendlyFieldName: 'Сума знижки',
    ruleType: RuleTypes.number,
    required: true,
  },
  expirationDate: {
    userFriendlyFieldName: 'Дійсний до',
    ruleType: RuleTypes.text,
    required: true,
  },
  services: {
    userFriendlyFieldName: 'Послуги',
    ruleType: RuleTypes.array,
    required: true,
    minLength: 1,
  },
};

export const validationErrorState: ValidationError<ValidationKeys> = {
  code: { errorMessage: '' },
  discountAmount: { errorMessage: '' },
  expirationDate: { errorMessage: '' },
  services: { errorMessage: '' },
};
