import {Services} from "../../types/services";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PromoCode} from "./types";

interface PromoCodeState {
    isModalOpen: boolean,
    createNew: boolean, // create if true, edit otherwise
    form: Omit<PromoCode, 'createdBy'>,
}

const initialState: PromoCodeState = {
    isModalOpen: false,
    createNew: true,
    form: {
        code: '', // unique
        discountAmount: 0,
        createdAt: '',
        expirationDate: '',
        services: [],
    }
};

export const promoCodeSlice = createSlice({
    name: 'promoCode',
    initialState,
    reducers: {
        resetPromoCodeModal: (state) => {
            state.isModalOpen = initialState.isModalOpen;
            state.createNew = initialState.createNew;
            state.form = { ...initialState.form };
        },
        initializeFormForEdit: (state, action: PayloadAction<PromoCode>) => {
            state.isModalOpen = true;
            state.createNew = false;
            state.form = { ...action.payload }
        },
        initializeFormForCreation: (state) => {
            state.isModalOpen = true;
            state.createNew = true;
            state.form = { ...initialState.form };
        },
        setCode: (state, action: PayloadAction<string>) => {
            state.form.code = action.payload;
        },
        setDiscountAmount: (state, action: PayloadAction<number>) => {
            state.form.discountAmount = action.payload;
        },
        setExpirationDate: (state, action: PayloadAction<string>) => {
            state.form.expirationDate = action.payload;
        },
        setService:  (state, action: PayloadAction<{ service: Services, action: 'add' | 'remove'}>) => {
            if (action.payload.action === "add") {
                state.form.services = [...state.form.services, action.payload.service];
                return;
            }

            // remove
            state.form.services = state.form.services.filter(s => s !== action.payload.service)
        },
    },
});

export const {
    resetPromoCodeModal,
    initializeFormForEdit,
    initializeFormForCreation,
    setCode,
    setDiscountAmount,
    setExpirationDate,
    setService,
} = promoCodeSlice.actions;

export default promoCodeSlice;
