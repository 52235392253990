import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQueryWithReAuth} from './baseQueryApp';

// initialize an empty api service
export const emptySplitApi = createApi({
    baseQuery: baseQueryWithReAuth,
    reducerPath: 'baseApi',

    tagTypes: [
        'CustomerProfile', // actually user's profile
        'PartnersList',
        'CustomersList',
        'ReservationsList',
        'ReservationsByUser',
        'SpotsConfiguration',
        'AccessZonesInfo',
        'SpotsCalculation',
        'PromoCodesList'
    ],
    endpoints: () => ({}),
});
