import React, {useState} from "react";
import {Box, Flex, IconButton, Text} from "@radix-ui/themes";
import ScanQRModal from "../features/ScanQR/ScanQRModal";
import {
    BookOpenIcon, CurrencyDollarIcon,
    QrCodeIcon,
    RocketLaunchIcon,
    TicketIcon,
    UserCircleIcon
} from "@heroicons/react/24/outline";
import BottomNavElement from "./BottomNavElement";
import {useNavigate} from "react-router-dom";

export default function BottomNav() {

    const [isModalOpened, setIsModalOpened] = useState(false);

    const navigate = useNavigate();

    // @ts-ignore
    const isFullScreen: boolean | null = window.navigator.standalone;

    return (
        <>
            <Flex
                position='absolute'
                bottom='0'
                p='2'
                px='4'
                pb='4'
                width="100%"
                height={isFullScreen ? '68px' : '56px'}
                justify="between"
                className="bottom-nav-container"
                style={{zIndex: 0}}
            >
                <BottomNavElement
                    label="Дашборд"
                    icon={<RocketLaunchIcon />}
                    onClick={() => navigate('/')}
                />

                <BottomNavElement
                    onClick={() => setIsModalOpened(true)}
                    label="Скан QR"
                    icon={<QrCodeIcon />}
                />

                <BottomNavElement
                    label="Резервації"
                    icon={<BookOpenIcon />}
                    onClick={() => navigate('/reservations-list')}
                />

                {/*<BottomNavElement*/}
                {/*    label="Платежі"*/}
                {/*    icon={<CurrencyDollarIcon />}*/}
                {/*    onClick={() => navigate('/payments-list')}*/}
                {/*/>*/}
            </Flex>
            {/*Must be outside positioned parent*/}
            <ScanQRModal open={isModalOpened} setOpen={setIsModalOpened} />
        </>
    )
}
