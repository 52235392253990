import { emptySplitApi } from '../../api/api';
import {GetCustomersListResponse, GetCustomersListRequest, Customer} from "./types";
import {PaginatedResponse} from "../../types/pagination";

export const customersAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getCustomersList: builder.query<PaginatedResponse<Customer>, GetCustomersListRequest>({
            query(params) {
                const {phoneNumber, name, ...par} = params;

                const request = par;
                if (phoneNumber) {
                    // @ts-expect-error
                    request['phoneNumber'] = phoneNumber;
                }

                if (name) {
                    // @ts-expect-error
                    request['name'] = name;
                }

                return {
                    url: '/customers',
                    params: request,
                };
            },
            transformResponse: (response: GetCustomersListResponse, meta, arg) => {

                const totalPages = Math.ceil(response.totalCount / arg.pageSize);

                console.log('response', 'error to be fixed here')

                return {
                    total: response.totalCount,
                    perPage: arg.pageSize,
                    page: arg.pageNumber,
                    totalPages: totalPages > 0 ? totalPages : 1,
                    data: response.customers,
                }
            },
            providesTags: (result, error, arg) => [
                { type: 'CustomersList', ...arg },
            ],
        }),
    }),
});

export const {
    useGetCustomersListQuery,
} = customersAPI;
