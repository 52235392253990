import {Flex, IconButton, Table} from "@radix-ui/themes";
import React from "react";
import {PencilIcon, TrashIcon} from "@heroicons/react/24/outline";
import {useGetPartnersListQuery} from "./partnersAPI";
import {useAppDispatch} from "../../store/hooks";
import {initializeFormForEdit, openDeleteModal} from "./partnersSlice";
import {format} from "@react-input/mask";
import {maskFormatConfig} from "../../helpers/mask";
import DeletePartnerModal from "./DeletePartnerModal";

export default function PartnersList() {

    const {data: partnersList} = useGetPartnersListQuery(null);

    const dispatch = useAppDispatch();

    return (
        <Table.Root>
            <Table.Header>
                <Table.Row>
                    <Table.ColumnHeaderCell>Імʼя</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Логін</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Дія</Table.ColumnHeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    partnersList?.map(partner => (
                        <Table.Row key={partner.id}>
                            <Table.RowHeaderCell>
                                {partner.firstName} {partner.lastName}
                            </Table.RowHeaderCell>
                            <Table.Cell>{partner.phone}</Table.Cell>
                            <Table.Cell>
                                <Flex gap="2">
                                    <IconButton>
                                        <PencilIcon
                                            width="16px"
                                            height="16px"
                                            onClick={() => {
                                                dispatch(initializeFormForEdit({
                                                    ...partner,
                                                    phone: format(
                                                        partner.phone,
                                                        {mask: maskFormatConfig.mask, replacement: maskFormatConfig.replacement}
                                                    ),
                                                    email: partner.email || '',
                                                    services: partner.services ? partner.services : [],
                                                    password: '',
                                                }))
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton color="red" onClick={() => dispatch(openDeleteModal(partner))}>
                                        <TrashIcon width="16px" height="16px" />
                                    </IconButton>
                                </Flex>
                            </Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>

            <DeletePartnerModal />
        </Table.Root>
    )
}
