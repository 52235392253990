import React from "react";
import {Button, Flex, Popover, TextField} from "@radix-ui/themes";
import {FunnelIcon} from "@heroicons/react/16/solid";
import {XCircleIcon} from "@heroicons/react/24/outline";

interface ClientsFilterByPhoneProps {
    phone: string,
    setPhone: (phone: string) => void,
}
export default function ClientsFilterByPhone(props: ClientsFilterByPhoneProps) {

    const { phone, setPhone } = props;

    return (
        <Popover.Root>
            <Popover.Trigger>
                <Button
                    variant="soft"
                    size="1"
                    mt="1"
                    color={phone !== null ? 'amber' : 'gray'}
                >
                    <FunnelIcon width="16" height="16" />
                    Номер {phone}
                </Button>
            </Popover.Trigger>
            <Popover.Content maxWidth="80dvw">
                <Flex mt="1" align="center" gap="2">

                    <TextField.Root
                        type="text"
                        value={phone ?? ''}
                        onChange={(e) => setPhone(e.target.value)}
                    />

                    <Popover.Close>
                        <Button
                            size="2"
                            color="gray"
                            variant={phone === null ? 'surface' : 'outline'}
                            onClick={() => setPhone('')}
                        >
                            <XCircleIcon width="16" height="16" /> очистити
                        </Button>
                    </Popover.Close>

                </Flex>
            </Popover.Content>
        </Popover.Root>
    )
}
