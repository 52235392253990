import React from "react";
import {Button, Callout, CheckboxGroup, Dialog, Flex, Text, TextField} from "@radix-ui/themes";
import {UserPlusIcon} from "@heroicons/react/24/outline";
import {getServiceNameByEnum, Services} from "../../types/services";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    initializeFormForCreation,
    resetPromoCodeModal,
    setCode,
    setDiscountAmount,
    setExpirationDate,
    setService,
} from "./promoCodeSlice";
import {RootState} from "../../store/store";
import {useFormValidation} from "../../helpers/validation";
import {
    rulesPromoCodesForm,
    validationErrorState,
    ValidationKeys
} from "./promoCodesFormValidationRules";
import {InformationCircleIcon} from "@heroicons/react/16/solid";
import {useCreateOrUpdatePromoCodeMutation} from "./promoCodesAPI";
import {CreateOrUpdatePromoCodeRequest} from "./types";

// todo: fix case when error stays in modal. STR: edit -> cause error -> close -> open "add"
export default function PromoCodeFormModal() {

    const dispatch = useAppDispatch();

    const {
        isModalOpen,
        createNew,
        form: { code, services, expirationDate, discountAmount, createdAt }
    } = useAppSelector((state: RootState) => state.promoCode);

    const [
        validationState,
        validate,
        onValidationInputChange
    ] = useFormValidation<ValidationKeys>(
        rulesPromoCodesForm,
        validationErrorState,
        {
            code,
            expirationDate,
            discountAmount,
            services,
        },
    );

    const [createOrUpdateRequest, { isLoading, isError }] = useCreateOrUpdatePromoCodeMutation();

    const servicesEnumKeys = Object.keys(Services).filter(key => isNaN(Number(key)));

    const onSubmit = async () => {
        const val = validate();

        if (! val.allValid) return;

        let payload: CreateOrUpdatePromoCodeRequest = {
            createNew,
            code,
            services,
            discountAmount,
            expirationDate,
        }

        const response = createOrUpdateRequest(payload);

        // @ts-ignore
        if (! response.error) {
            dispatch(resetPromoCodeModal());
            // dispatch(emptySplitApi.util?.invalidateTags(['PartnersList']));
        }
    };

    return (
        <Flex>
            <Button onClick={() => dispatch(initializeFormForCreation())}>
                <UserPlusIcon width="20px" height="20px" />
                Створити промокод
            </Button>
            <Dialog.Root open={isModalOpen}>
                <Dialog.Content onOpenAutoFocus={(e) => e.preventDefault()} maxWidth="740px">
                    <Dialog.Title>{createNew ? 'Створити' : 'Оновити'} промокод</Dialog.Title>

                    <Callout.Root size="1" mt="1" mb="4" style={{ alignItems: 'center' }}>
                        <Callout.Icon>
                            <InformationCircleIcon width="16" height="16" />
                        </Callout.Icon>
                        <Callout.Text size='1'>
                            Промокод не можна видаляти. Усі промокоди будуть збережені.
                        </Callout.Text>
                    </Callout.Root>

                    <Flex direction='column' gap='4'>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Код
                            </Text>
                            <TextField.Root
                                placeholder="Summer2025"
                                value={code}
                                type="text"
                                disabled={! createNew}
                                onChange={(e) => {
                                    dispatch(setCode(e.target.value));
                                    onValidationInputChange('code');
                                }}
                            />
                            <Text size="1" color="red">{validationState.code.errorMessage}</Text>
                        </label>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Сума знижки (у грн)
                            </Text>
                            <TextField.Root
                                placeholder="Клопотенко"
                                value={discountAmount}
                                type="number"
                                onChange={(e) => {
                                    dispatch(setDiscountAmount(parseInt(e.target.value)));
                                    onValidationInputChange('discountAmount');
                                }}
                            />
                            <Text size="1" color="red">{validationState.discountAmount.errorMessage}</Text>
                        </label>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Дійсний до
                            </Text>
                            <TextField.Root
                                type="date"
                                value={expirationDate}
                                min={new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":")).split('T')[0]}
                                onChange={(e) => {
                                    dispatch(setExpirationDate(e.target.value));
                                    onValidationInputChange('expirationDate');
                                }}
                            />
                        </label>
                        <Text size="1" color="red">{validationState.expirationDate.errorMessage}</Text>
                        <Text as="div" size="2" mt="2" weight="bold">
                            Включені послуги
                        </Text>
                        <CheckboxGroup.Root size="2" name="services" value={services.map(s => s.toString())}>
                            {
                                servicesEnumKeys.map((key: string) => (
                                    // @ts-ignore
                                    <CheckboxGroup.Item
                                        key={key}
                                        // @ts-expect-error
                                        value={Services[key].toString()}
                                        onClick={(e) => {
                                            dispatch(setService({
                                                // @ts-expect-error
                                                service: Services[key],
                                                // @ts-expect-error
                                                action: services.includes(Services[key]) ? 'remove' : 'add',
                                            }));
                                            onValidationInputChange('services');
                                        }}
                                    >
                                        {/*@ts-expect-error */}
                                        { getServiceNameByEnum(Services[key])}
                                    </CheckboxGroup.Item>
                                ))
                            }
                        </CheckboxGroup.Root>
                        <Text size="1" color="red">{validationState.services.errorMessage}</Text>
                    </Flex>

                    <Flex gap="2" mt="6" justify="end">
                        <Button variant="soft" color="gray" onClick={() => dispatch(resetPromoCodeModal())}>
                            Закрити
                        </Button>

                        <Button onClick={onSubmit} disabled={isLoading}>
                            { createNew ? 'Створити' : 'Оновити' }
                        </Button>
                    </Flex>
                    <Flex justify="end" mt="1">
                        {
                            isError && <Text size="1" color="red">Сталася помилка. Спробуйте знову</Text>
                        }
                    </Flex>
                </Dialog.Content>
            </Dialog.Root>
        </Flex>
    )
}
