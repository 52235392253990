import React, {useEffect, useState} from "react";
import {Flex, Heading, IconButton, Table} from "@radix-ui/themes";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {useGetCustomersListQuery} from "./customersAPI";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {useNavigate} from "react-router-dom";
import {Roles} from "../../types/roles";
import Pagination from "../../shared/Pagination";
import {Customer} from "./types";
import ViewCustomerModal from "./ViewCustomerModal";
import ClientsFilterByPhone from "./filters/ClientsFilterByPhone";
import ClientsFilterByName from "./filters/ClientsFilterByName";

export default function ClientsList() {

    const PER_PAGE = 10;

    const [page, setPage] = useState(1);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

    const { data: profile } = useGetProfileQuery(null);
    const {data: customersList, isLoading, isFetching} = useGetCustomersListQuery({
        pageSize: PER_PAGE,
        pageNumber: page,
        phoneNumber: phone,
        name,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (profile && profile.role !== Roles.Admin) {
            navigate('/');
        }
    }, [profile]);

    return (
        <Flex direction='column' pt='4'>
            <Heading>Відвідувачі</Heading>

            <Flex mt='4' direction='column' gap='4'>

                <Flex gap="1" wrap="wrap">
                    <ClientsFilterByPhone phone={phone} setPhone={setPhone} />
                    <ClientsFilterByName name={name} setName={setName} />
                </Flex>

                <Table.Root size="1">
                    <Table.Header>
                        <Table.Row>
                            <Table.ColumnHeaderCell>№</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Імʼя</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Номер</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Дія</Table.ColumnHeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            customersList?.data.map(customer => (
                                <Table.Row key={customer.id}>
                                    <Table.RowHeaderCell>{customer.id}</Table.RowHeaderCell>
                                    <Table.RowHeaderCell>{customer.firstName} {customer.lastName}</Table.RowHeaderCell>
                                    <Table.Cell>{customer.phone}</Table.Cell>
                                    <Table.Cell>
                                        <IconButton onClick={() => {
                                            setModalOpen(true);
                                            setSelectedCustomer(customer);
                                        }}>
                                            <MagnifyingGlassIcon width="16px" height="16px" />
                                        </IconButton>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table.Root>

                {
                    customersList?.data && (
                        <Pagination
                            perPage={PER_PAGE}
                            page={page}
                            totalPages={customersList.totalPages}
                            total={customersList.total}

                            setPage={(page) => setPage(page)}
                            isLoading={isLoading || isFetching}
                        />
                    )
                }

                <ViewCustomerModal open={modalOpen} setOpen={setModalOpen} customer={selectedCustomer} />
            </Flex>
        </Flex>
    );
}
