import React, {useState} from "react";
import {Box, Flex, Heading, IconButton, Spinner, Table} from "@radix-ui/themes";
import {EyeIcon} from "@heroicons/react/24/outline";
import ReservationReviewModal from "./ReservationReviewModal";
import {useGetReservationsQuery} from "./reservationsAPI";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {RootState} from "../../store/store";
import Pagination from "../../shared/Pagination";
import {setPage} from "./reservationsSlice";
import {formatDateToISO} from "../../helpers/dateFormat";
import CreatePayment from "./CreatePayment";
import {Reservation} from "./types";
import ReservationsFilterStatus from "./ReservationsFilterStatus";
import ReservationsFilterByDate from "./ReservationsFilterByDate";
import ReservationsFilterByID from "./ReservationsFilterByID";
import ReservationsFilterByService from "./ReservationsFilterByService";
import ReservationsFilterByPhone from "./ReservationsFilterByPhone";

export default function ReservationsList() {

    const [modalOpen, setModalOpen] = useState(false);
    const [reservation, setReservation] = useState<Reservation | null>(null);

    const dispatch = useAppDispatch();

    const {
        page,
        perPage,
        status,
        date,
        reservationID,
        services,
        phone,
    } = useAppSelector((state: RootState) => state.reservations);

    const {
        data: reservationsList,
        isLoading,
        isFetching
    } = useGetReservationsQuery({
        pageNumber: page,
        pageSize: perPage,
        status,
        date,
        reservationId: reservationID,
        services,
        phone,
    }, { pollingInterval: 60 * 1000}); // every min

    return (
        <>
            <Flex direction='column' pt='4'>
                <Heading>Резервації</Heading>

                <Box my="2">
                    <CreatePayment />
                </Box>
                <Flex gap="1" wrap="wrap">
                    <ReservationsFilterStatus />
                    <ReservationsFilterByDate />
                    <ReservationsFilterByID />
                    <ReservationsFilterByService />
                    <ReservationsFilterByPhone />
                </Flex>

                <Flex direction='column' gap='4'>
                    <Flex justify="center">
                        {
                            (isLoading) && <Flex justify="center" mt="2"><Spinner size="3" /> </Flex>
                        }
                    </Flex>
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>№</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Імʼя</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Дата</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Дія</Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            {
                                reservationsList?.data.map((reservation) => (
                                    <Table.Row key={reservation.id}>
                                        <Table.RowHeaderCell>{reservation.id}</Table.RowHeaderCell>
                                        <Table.RowHeaderCell>
                                            {reservation.customer?.firstName} {reservation.customer?.lastName}
                                        </Table.RowHeaderCell>
                                        <Table.Cell>{formatDateToISO(new Date(reservation.dateFrom)).split('T')[0]}</Table.Cell>
                                        <Table.Cell>
                                            <Flex gap="2">
                                                <IconButton
                                                    onClick={() => {
                                                        setModalOpen(true);
                                                        setReservation(reservation);
                                                    }}
                                                >
                                                    <EyeIcon width="16px" height="16px" />
                                                </IconButton>
                                            </Flex>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }

                        </Table.Body>
                    </Table.Root>

                    {
                        reservationsList?.data && (
                            <Pagination
                                perPage={perPage}
                                page={page}
                                totalPages={reservationsList?.totalPages}
                                total={reservationsList.total}

                                setPage={(page) => dispatch(setPage(page))}
                                isLoading={isLoading || isFetching}
                            />
                        )
                    }
                </Flex>
            </Flex>

            <ReservationReviewModal
                open={modalOpen}
                reservation={reservation}
                setOpen={(flag) => {
                    setModalOpen(flag);
                    // timeout for animation
                    if (! flag) setTimeout(() => {setReservation(null)}, 300)
                }}
            />
        </>
    )
}
