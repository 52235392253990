import React from "react";
import {Button, Flex, Popover, TextField} from "@radix-ui/themes";
import {FunnelIcon} from "@heroicons/react/16/solid";
import {setDateFrom} from "../exportReservationsSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {RootState} from "../../../store/store";
import {XCircleIcon} from "@heroicons/react/24/outline";

export default function ExportServicesFilterByDateFrom() {

    const today = new Date();
    const todayString = today.toISOString().split('T')[0];

    const { dateFrom } = useAppSelector((state: RootState) => state.exportReservations);

    const dispatch = useAppDispatch();

    return (
        <Popover.Root>
            <Popover.Trigger>
                <Button
                    variant="soft"
                    size="1"
                    mt="1"
                    color={dateFrom !== null ? 'amber' : 'gray'}
                >
                    <FunnelIcon width="16" height="16" />
                    Дата від{`${dateFrom === null ? '' : `: ${dateFrom}`}`}
                </Button>
            </Popover.Trigger>
            <Popover.Content maxWidth="80dvw">
                <Flex mt="1" align="center" gap="2">

                    <TextField.Root
                        type="date"
                        value={dateFrom ?? ''}
                        onChange={(e) => dispatch(setDateFrom(e.target.value))}
                    />

                    <Popover.Close>
                        <Button
                            size="2"
                            color="gray"
                            variant={dateFrom === null ? 'surface' : 'outline'}
                            onClick={() => dispatch(setDateFrom(todayString))}
                        >
                            <XCircleIcon width="16" height="16" /> сьогодні
                        </Button>
                    </Popover.Close>

                </Flex>
            </Popover.Content>
        </Popover.Root>
    )
}
