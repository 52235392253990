import { emptySplitApi } from '../../api/api';
import {CreatePartnerRequest, CreatePartnerResponse, GetPartnersListResponse, UpdatePartnerRequest} from "./types";

export const partnersAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        // will return empty object for admin login
        getPartnersList: builder.query<GetPartnersListResponse, null>({
            query() {
                return {
                    url: '/partners',
                    method: 'GET',
                };
            },
            providesTags: ['PartnersList'],
        }),

        createPartner: builder.mutation<CreatePartnerResponse, CreatePartnerRequest>({
            query(data) {
                return {
                    url: '/createPartner',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: () => ['PartnersList'],
        }),
        updatePartner: builder.mutation<null, UpdatePartnerRequest>({
            query(data) {
                return {
                    url: '/partnerProfile',
                    method: 'PATCH',
                    body: data,
                };
            },
            invalidatesTags: () => ['PartnersList'],
        }),
        deletePartner: builder.mutation<null, { userId: number }>({
            query(data) {
                return {
                    url: '/deletePartner',
                    method: 'DELETE',
                    body: data,
                };
            },
            invalidatesTags: () => ['PartnersList'],
        }),
    }),
});

export const {
    useGetPartnersListQuery,
    useCreatePartnerMutation,
    useUpdatePartnerMutation,
    useDeletePartnerMutation,
} = partnersAPI;
