import {
  RulesForFieldsType,
  RuleTypes,
  ValidationError,
} from '../../../helpers/validation';

export type ValidationKeys = 'firstName' | 'lastName' | 'email' | 'phone' | 'services' | 'password';

export const rulesPartnersForm: RulesForFieldsType<ValidationKeys> = {
  firstName: {
    userFriendlyFieldName: 'Імʼя',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 3,
    maxLength: 64,
  },
  lastName: {
    userFriendlyFieldName: 'Прізвище',
    ruleType: RuleTypes.text,
    required: false,
    minLength: 3,
    maxLength: 64,
  },
  email: {
    userFriendlyFieldName: 'Пошта',
    ruleType: RuleTypes.email,
    required: false,
  },
  phone: {
    userFriendlyFieldName: 'Номер телефону',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 13,
    maxLength: 13,
  },
  services: {
    userFriendlyFieldName: 'Права доступу',
    ruleType: RuleTypes.array,
    required: true,
    minLength: 1,
  },
  password: {
    userFriendlyFieldName: 'Пароль',
    ruleType: RuleTypes.text,
    required: true,
    minLength: 6,
  }
};

export const rulesPartnersEditForm: RulesForFieldsType<ValidationKeys> = {
  ...rulesPartnersForm,
  password: {
    ...rulesPartnersForm.password,
    required: false,
  }
}

export const validationErrorState: ValidationError<ValidationKeys> = {
  firstName: { errorMessage: '' },
  lastName: { errorMessage: '' },
  email: { errorMessage: '' },
  phone: { errorMessage: '' },
  services: { errorMessage: '' },
  password: { errorMessage: '' },
};
