import {Flex, Heading} from "@radix-ui/themes";
import React, {useEffect} from "react";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {Roles} from "../../types/roles";
import {useNavigate} from "react-router-dom";
import ExportReservationsView from "./ExportReservationsView";

export default function ExportReservations() {

    const { data: profile } = useGetProfileQuery(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (profile && profile.role !== Roles.Admin) {
            navigate('/');
        }
    }, [profile]);

    return (
        <Flex direction='column' pt='4'>
            <Heading>Експорт резервацій</Heading>

            {
                profile && profile.role === Roles.Admin && (
                    <Flex mt='4' direction='column' gap='4'>
                        <ExportReservationsView />
                    </Flex>
                )
            }
        </Flex>
    );
}
