import {Button, Flex, Popover, TextField} from "@radix-ui/themes";
import React from "react";
import {FunnelIcon} from "@heroicons/react/16/solid";
import {setDate, setReservationID} from "./reservationsSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {RootState} from "../../store/store";
import {XCircleIcon} from "@heroicons/react/24/outline";

export default function ReservationsFilterByID() {

    const { reservationID } = useAppSelector((state: RootState) => state.reservations);

    const dispatch = useAppDispatch();

    return (
        <Popover.Root>
            <Popover.Trigger>
                <Button
                    variant="soft"
                    size="1"
                    mt="1"
                    color={reservationID !== null ? 'amber' : 'gray'}
                >
                    <FunnelIcon width="16" height="16" />
                    №{`${reservationID === null ? '' : `: ${reservationID}`}`}
                </Button>
            </Popover.Trigger>
            <Popover.Content maxWidth="80dvw">
                <Flex mt="1" align="center" gap="2">

                    <TextField.Root
                        type="number"
                        value={reservationID ?? ''}
                        onChange={(e) => dispatch(setReservationID(parseInt(e.target.value)))}
                    />

                    <Popover.Close>
                        <Button
                            size="2"
                            color="gray"
                            variant={reservationID === null ? 'surface' : 'outline'}
                            onClick={() => dispatch(setReservationID(null))}
                        >
                            <XCircleIcon width="16" height="16" /> Прибрати
                        </Button>
                    </Popover.Close>

                </Flex>
            </Popover.Content>
        </Popover.Root>
    )
}
