import {Flex, Heading, Text, TextField} from "@radix-ui/themes";
import React, {useEffect, useState} from "react";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {Roles} from "../../types/roles";
import {useNavigate} from "react-router-dom";
import {Services} from "../../types/services";
import FreeSpots from "../Reservations/components/FreeSpots";

// todo: fix potential bug as ISO string ignores timezone
export default function FreeSpotsView() {

    const today = new Date();
    const todayString = today.toISOString().split('T')[0];

    const [filterDate, setFilterDate] = useState<string>(todayString);
    const { data: profile } = useGetProfileQuery(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (profile && profile.role !== Roles.Admin) {
            navigate('/');
        }
    }, [profile]);

    const nextDay = new Date(new Date().setTime(new Date(filterDate).getTime() + (24 * 60 * 60 * 1000))).toISOString().split('T')[0];
    const prevDay = new Date(new Date().setTime(new Date(filterDate).getTime() - (24 * 60 * 60 * 1000))).toISOString().split('T')[0];

    return (
        <Flex direction='column' pt='4'>
            <Heading>Вільні місця</Heading>

            <Flex my="4">
                <TextField.Root
                    type="date"
                    value={filterDate}
                    onChange={(e) => setFilterDate(e.target.value)}
                />
            </Flex>

            <Flex direction="column">
                <Heading size="2">На обрану дату {filterDate}</Heading>
                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Вхідний квиток:</Text>
                    <FreeSpots service={Services.ENTRY_TICKET} date={filterDate} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги:</Text>
                    <FreeSpots service={Services.SUN_LOUNGERS} date={filterDate} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги зі столиком:</Text>
                    <FreeSpots service={Services.SUN_LOUNGERS_WITH_TABLE} date={filterDate} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги біля басейну:</Text>
                    <FreeSpots service={Services.MINIPOOL} date={filterDate} onlyNumber />
                </Flex>
            </Flex>

            <Flex direction="column" mt="4">
                <Heading size="2">+1 день {nextDay}</Heading>
                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Вхідний квиток:</Text>
                    <FreeSpots service={Services.ENTRY_TICKET} date={nextDay} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги:</Text>
                    <FreeSpots service={Services.SUN_LOUNGERS} date={nextDay} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги зі столиком:</Text>
                    <FreeSpots service={Services.SUN_LOUNGERS_WITH_TABLE} date={nextDay} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги біля басейну:</Text>
                    <FreeSpots service={Services.MINIPOOL} date={nextDay} onlyNumber />
                </Flex>
            </Flex>

            <Flex direction="column" mt="4">
                <Heading size="2">-1 день {prevDay}</Heading>
                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Вхідний квиток:</Text>
                    <FreeSpots service={Services.ENTRY_TICKET} date={prevDay} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги:</Text>
                    <FreeSpots service={Services.SUN_LOUNGERS} date={prevDay} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги зі столиком:</Text>
                    <FreeSpots service={Services.SUN_LOUNGERS_WITH_TABLE} date={prevDay} onlyNumber />
                </Flex>

                <Flex direction="row" align="center" mt="1" gap="1">
                    <Text size="1">Шезлонги біля басейну:</Text>
                    <FreeSpots service={Services.MINIPOOL} date={prevDay} onlyNumber />
                </Flex>
            </Flex>
        </Flex>
    );
}
