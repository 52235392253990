import React, {ReactElement} from "react";
import {Navigate} from "react-router-dom";
import {useAppSelector} from "../store/hooks";
import {RootState} from "../store/store";

// todo: check if admin or manager
// todo: can be refactored https://medium.com/@dennisivy/creating-protected-routes-with-react-router-v6-2c4bbaf7bc1c
export default function ProtectedRoute({ children }: { children: ReactElement }) {
    const { isUserLoggedIn, authInProgress} = useAppSelector((state: RootState) => state.authentication);

    if (authInProgress || isUserLoggedIn) {
        return children;
    }

    return <Navigate to="/login" replace />;
}
