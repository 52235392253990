import {Services} from "../../types/services";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PartnerEntry} from "./types";

interface PartnersState {
    isModalOpen: boolean,
    id?: number, // create if undefined, edit otherwise
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string,
    services: Services[],

    deleteModal: {
        deleteModalOpen: boolean,
        partner: PartnerEntry | null,
    },
}

const initialState: PartnersState = {
    isModalOpen: false,
    id: undefined,
    firstName: '',
    lastName: '',
    email: '',
    phone: '+380',
    password: '',
    services: [],

    deleteModal: {
        deleteModalOpen: false,
        partner: null,
    },
};

export const partnersManagementSlice = createSlice({
    name: 'partnersManagement',
    initialState,
    reducers: {
        resetPartnersForm: (state) => {
            state.isModalOpen = initialState.isModalOpen;
            state.id = initialState.id;
            state.firstName = initialState.firstName;
            state.lastName = initialState.lastName;
            state.email = initialState.email;
            state.phone = initialState.phone;
            state.services = initialState.services;
            state.password = initialState.password;
        },
        initializeFormForEdit: (state, action: PayloadAction<Omit<PartnersState, 'isModalOpen' | 'deleteModal'>>) => {
            state.isModalOpen = true;
            state.id = action.payload.id;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
            state.services = action.payload.services;
            state.password = '';
        },
        openCloseModal: (state, action: PayloadAction<boolean>) => {
            state.isModalOpen = action.payload;
        },
        openDeleteModal: (state, action: PayloadAction<PartnerEntry>) => {
            state.deleteModal = {
                deleteModalOpen: true,
                partner: action.payload,
            };
        },
        closeDeleteModal: (state) => {
            state.deleteModal = {
                deleteModalOpen: false,
                partner: null,
            };
        },
        setFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setService:  (state, action: PayloadAction<{ service: Services, action: 'add' | 'remove'}>) => {
            if (action.payload.action === "add") {
                state.services = [...state.services, action.payload.service];
                return;
            }

            // remove
            state.services = state.services.filter(s => s !== action.payload.service)
        },
    },
});

export const {
    openCloseModal,
    resetPartnersForm,
    initializeFormForEdit,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setService,
    openDeleteModal,
    closeDeleteModal,
    setPassword,
} = partnersManagementSlice.actions;

export default partnersManagementSlice;
