import { configureStore } from '@reduxjs/toolkit'
import {emptySplitApi} from "../api/api";
import authSlice from "../features/Login/authSlice";
import profileSlice from "../features/Profile/profileSlice";
import partnersManagementSlice from "../features/PartnersManagement/partnersSlice";
import reservationsSlice from "../features/Reservations/reservationsSlice";
import exportReservationsSlice from "../features/ExportReservations/exportReservationsSlice";
import promoCodeSlice from "../features/PromoCodes/promoCodeSlice";

const store = configureStore({
    reducer: {
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
        [authSlice.name]: authSlice.reducer,
        [profileSlice.name]: profileSlice.reducer,

        [partnersManagementSlice.name]: partnersManagementSlice.reducer,
        [reservationsSlice.name]: reservationsSlice.reducer,
        [exportReservationsSlice.name]: exportReservationsSlice.reducer,
        [promoCodeSlice.name]: promoCodeSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(emptySplitApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
