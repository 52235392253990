import React from "react";
import {Button, CheckboxGroup, Dialog, Flex, Text, TextField} from "@radix-ui/themes";
import {useMask} from "@react-input/mask";
import {UserPlusIcon} from "@heroicons/react/24/outline";
import {getServiceNameByEnum, Services} from "../../../types/services";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    openCloseModal,
    resetPartnersForm,
    setEmail,
    setFirstName,
    setLastName, setPassword,
    setPhone,
    setService
} from "../partnersSlice";
import {RootState} from "../../../store/store";
import {useFormValidation} from "../../../helpers/validation";
import {
    rulesPartnersEditForm,
    rulesPartnersForm,
    validationErrorState,
    ValidationKeys
} from "./partnersFormValidationRules";
import {formatPhoneAfterMask} from "../../../helpers/utils";
import {maskConfig} from "../../../helpers/mask";
import {useCreatePartnerMutation, useUpdatePartnerMutation} from "../partnersAPI";
import {CreatePartnerRequest} from "../types";
import {ClipboardIcon} from "@heroicons/react/24/outline";

// todo: fix case when error stays in modal. STR: edit -> cause error -> close -> open "add"
export default function AddPartnerForm() {

    const phoneInputRef = useMask(maskConfig);

    const dispatch = useAppDispatch();

    const {
        isModalOpen,
        id,
        firstName,
        lastName,
        email,
        phone,
        password,
        services,
    } = useAppSelector((state: RootState) => state.partnersManagement);

    const [
        validationState,
        validate,
        onValidationInputChange
    ] = useFormValidation<ValidationKeys>(
        Boolean(id) ? rulesPartnersEditForm : rulesPartnersForm,
        validationErrorState,
        {
            firstName,
            lastName,
            email,
            password,
            phone: formatPhoneAfterMask(phone),
            services,
        },
    );

    const [creatingPartnerRequest, { isLoading: isCreating, isError: isCreatingError }] = useCreatePartnerMutation();
    const [updatingPartnerRequest, { isLoading: isUpdating, isError: isUpdatingError }] = useUpdatePartnerMutation();

    const servicesEnumKeys = Object.keys(Services).filter(key => isNaN(Number(key)));

    const onSubmit = async () => {
        const val = validate();

        if (! val.allValid) return;

        const isUpdating = Boolean(id);

        let payload: CreatePartnerRequest = {
            phone: formatPhoneAfterMask(phone),
            firstName,
            lastName,
            email,
            services,
            password,
        }

        const response = isUpdating
            // @ts-ignore
            ? await updatingPartnerRequest({...payload, Id: id.toString()})
            : await creatingPartnerRequest(payload)

        // @ts-ignore
        if (! response.error) {
            dispatch(openCloseModal(false));
            // dispatch(emptySplitApi.util?.invalidateTags(['PartnersList']));
        }
    };

    return (
        <Flex>
            <Button onClick={() => {
                dispatch(resetPartnersForm());
                dispatch(openCloseModal(true));
            }}>
                <UserPlusIcon width="20px" height="20px" />
                Додати партнера (адміністратора)
            </Button>
            <Dialog.Root open={isModalOpen}>
                <Dialog.Content onOpenAutoFocus={(e) => e.preventDefault()} maxWidth="740px">
                    <Dialog.Title>{id ? 'Оновити' : 'Додати'} партнера (адміністратора)</Dialog.Title>

                    <Flex direction='column' gap='4'>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Імʼя
                            </Text>
                            <TextField.Root
                                placeholder="Валерій"
                                value={firstName}
                                type="text"
                                onChange={(e) => {
                                    dispatch(setFirstName(e.target.value));
                                    onValidationInputChange('firstName');
                                }}
                            />
                            <Text size="1" color="red">{validationState.firstName.errorMessage}</Text>
                        </label>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Прізвище
                            </Text>
                            <TextField.Root
                                placeholder="Клопотенко"
                                value={lastName}
                                type="text"
                                onChange={(e) => {
                                    dispatch(setLastName(e.target.value));
                                    onValidationInputChange('lastName');
                                }}
                            />
                            <Text size="1" color="red">{validationState.lastName.errorMessage}</Text>
                        </label>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Номер телефону
                            </Text>
                            <TextField.Root
                                type="tel"
                                ref={phoneInputRef}
                                value={phone}
                                placeholder="Ваш номер"
                                onChange={(e) => {
                                    dispatch(setPhone(e.target.value));
                                    onValidationInputChange('phone');
                                }}
                            />
                            <Text size="1" color="red">{validationState.phone.errorMessage}</Text>
                        </label>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                E-mail
                            </Text>
                            <TextField.Root
                                type="email"
                                value={email}
                                placeholder="example@gmail.com"
                                onChange={(e) => {
                                    dispatch(setEmail(e.target.value));
                                    onValidationInputChange('email');
                                }}
                            />
                            <Text size="1" color="red">{validationState.email.errorMessage}</Text>
                        </label>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Пароль
                            </Text>
                            <TextField.Root
                                type="text"
                                value={password}
                                placeholder="*******"
                                onChange={(e) => {
                                    dispatch(setPassword(e.target.value));
                                    onValidationInputChange('password');
                                }}
                            />
                            <Text size="1" color="red">{validationState.password.errorMessage}</Text>

                            <Flex justify="end">
                                {
                                    (! password) && (
                                        <Button
                                            variant="ghost"
                                            m="1"
                                            onClick={() => {
                                                const password = Math.random().toString(36).slice(-10);
                                                dispatch(setPassword(password));
                                            }}
                                        >
                                            Сгенерувати
                                        </Button>
                                    )
                                }
                                {
                                    password && (
                                        <Button
                                            variant="ghost"
                                            m="1"
                                            onClick={() => {
                                                if (navigator.clipboard) {
                                                    navigator.clipboard.writeText(password);
                                                }
                                            }}
                                        >
                                            <ClipboardIcon width="20" height="20" /> Скопіювати
                                        </Button>
                                    )
                                }
                            </Flex>
                        </label>
                        <Text as="div" size="2" mt="2" weight="bold">
                            Права (доступ до послуг)
                        </Text>
                        <CheckboxGroup.Root size="2" name="services" value={services.map(s => s.toString())}>
                            {
                                servicesEnumKeys.map((key: string) => (
                                    // @ts-ignore
                                    <CheckboxGroup.Item
                                        key={key}
                                        // @ts-expect-error
                                        value={Services[key].toString()}
                                        onClick={(e) => {
                                            dispatch(setService({
                                                // @ts-expect-error
                                                service: Services[key],
                                                // @ts-expect-error
                                                action: services.includes(Services[key]) ? 'remove' : 'add',
                                            }));
                                            onValidationInputChange('services');
                                        }}
                                    >
                                        {/*@ts-expect-error */}
                                        { getServiceNameByEnum(Services[key])}
                                    </CheckboxGroup.Item>
                                ))
                            }
                        </CheckboxGroup.Root>
                        <Text size="1" color="red">{validationState.services.errorMessage}</Text>
                    </Flex>

                    <Flex gap="2" mt="6" justify="end">
                        <Button variant="soft" color="gray" onClick={() => dispatch(openCloseModal(false))}>
                            Закрити
                        </Button>

                        <Button onClick={onSubmit} disabled={isCreating || isUpdating}>
                            { id ? 'Оновити' : 'Додати' }
                        </Button>
                    </Flex>
                    <Flex justify="end" mt="1">
                        {
                            (isCreatingError || isUpdatingError) && <Text size="1" color="red">Сталася помилка. Спробуйте знову</Text>
                        }
                    </Flex>
                </Dialog.Content>
            </Dialog.Root>
        </Flex>
    )
}
