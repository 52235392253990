import React from "react";
import {useGetSpotsCalculationQuery} from "../../Dashboard/dashboardAPI";
import {Services} from "../../../types/services";
import {Text} from "@radix-ui/themes";

interface FreeSpotsProps {
    service: Services,
    date?: string,
    onlyNumber?: boolean,
}

export default function FreeSpots(props: FreeSpotsProps) {

    const { service, date, onlyNumber = false } = props;

    const { data } = useGetSpotsCalculationQuery({ date }, { skip: !date});

    if (! data) return null;

    if (service === Services.ENTRY_TICKET) {
        return <Text size="1">{onlyNumber ? '' : 'Кількість доступних місць:'} {data.numberOfEntryTickets}</Text>
    }


    if (service === Services.SUN_LOUNGERS) {
        return <Text size="1">{onlyNumber ? '' : 'Кількість доступних місць:'} {data.numberOfSunLoungers}</Text>
    }

    if (service === Services.SUN_LOUNGERS_WITH_TABLE) {
        return <Text size="1">{onlyNumber ? '' : 'Кількість доступних місць:'} {data.numberOfSunLoungersWithTable}</Text>
    }

    if (service === Services.MINIPOOL) {
        return <Text size="1">{onlyNumber ? '' : 'Кількість доступних місць:'} {data.numberOfMinipool}</Text>
    }

    return null;
}
