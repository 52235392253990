import { emptySplitApi } from '../../api/api';
import {GetAccessZonesResponse} from "./types";

export const scanInfoAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        getAccessZones: builder.query<GetAccessZonesResponse, { userId: string, date: string }>({
            query(params) {
                return {
                    url: '/userAccessZones',
                    params,
                };
            },
            providesTags: (result, error, arg) => [
                { type: 'AccessZonesInfo', ...arg },
            ],
        }),
    }),
});

export const {
    useGetAccessZonesQuery,
} = scanInfoAPI;
