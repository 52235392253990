import { emptySplitApi } from '../../api/api';
import {
    GetExportServicesRequest,
    ExportServicesResponseEntry,
} from "./types";

export const exportAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getServicesExport: builder.mutation<ExportServicesResponseEntry[], GetExportServicesRequest>({
            query(data) {
                return {
                    url: '/exportServices',
                    method: 'POST',
                    body: data,
                };
            },
        }),
    }),
});

export const {
    useGetServicesExportMutation,
} = exportAPI;
