import { emptySplitApi } from '../../api/api';
import {
    BookingRequestPayload,
    GetReservationsListRequest,
    GetReservationsListResponse,
    Reservation,
    UpdateReservationRequest
} from "./types";
import {PaginatedResponse} from "../../types/pagination";

export const reservationsAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getReservations: builder.query<PaginatedResponse<Reservation>, GetReservationsListRequest>({
            query(params) {
                const {
                    pageSize,
                    pageNumber,
                    status,
                    date,
                    reservationId,
                    services,
                    phone,
                } = params;

                const request = { pageSize, pageNumber, services };
                // @ts-ignore
                if (status) request.status = status;
                // @ts-ignore
                if (date) request.date = date;
                // @ts-expect-error
                if (reservationId) request.reservationId = reservationId;
                // @ts-expect-error
                if (phone) request.phone = phone;

                return {
                    url: '/reservations',
                    method: 'POST',
                    body: {...request },
                };
            },
            transformResponse: (response: GetReservationsListResponse, meta, arg) => {
                const totalPages = Math.ceil(response.totalCount / arg.pageSize);

                return {
                    total: response.totalCount,
                    perPage: arg.pageSize,
                    page: arg.pageNumber,
                    totalPages: totalPages > 0 ? totalPages : 1,
                    data: response.reservations,
                }
            },
            providesTags: (result, error, arg) => [
                { type: 'ReservationsList', ...arg },
            ],
        }),

        // todo: check types of response
        // todo: see if will be used
        getReservationsByUser: builder.query<Reservation, { userId: string, date: string }>({
            query(params) {
                return {
                    url: `/reservations/${params.userId}`,
                    params: {
                        pageNumber: 1,
                        pageSize: 1000,
                        date: params.date,
                    }
                };
            },
            providesTags: (result, error, arg) => [
                { type: 'ReservationsByUser', ...arg },
            ],
        }),

        createBooking: builder.mutation<{reservationId: number}, BookingRequestPayload>({
            query(data) {

                const {userID, ...rest} = data;

                const request = rest;
                if (userID) {
                    // @ts-expect-error
                    request['userID'] = userID;
                }

                return {
                    url: '/booking',
                    method: 'POST',
                    body: request,
                };
            },
            invalidatesTags: ['ReservationsList', 'SpotsCalculation'],
        }),

        updateReservation: builder.mutation<any, UpdateReservationRequest>({
            query(data) {
                return {
                    url: '/reservation',
                    method: 'PATCH',
                    body: data,
                };
            },
            invalidatesTags: ['ReservationsList', 'SpotsCalculation'],
        }),
    }),
});

export const {
    useGetReservationsQuery,
    useGetReservationsByUserQuery,
    useCreateBookingMutation,
    useUpdateReservationMutation,
} = reservationsAPI;
