import React from "react";
import {Button, Flex, Popover} from "@radix-ui/themes";
import {FunnelIcon} from "@heroicons/react/16/solid";
import {setStatus} from "../exportReservationsSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {RootState} from "../../../store/store";
import {getLabelsByReservationStatus} from "../../Reservations/helpers";
import {ReservationStatusString} from "../../Reservations/types";

export default function ExportServicesFilterByStatus() {

    const { status } = useAppSelector((state: RootState) => state.exportReservations);

    const dispatch = useAppDispatch();

    return (
        <Popover.Root>
            <Popover.Trigger>
                <Button
                    variant="soft"
                    size="1"
                    mt="1"
                    color={status !== null ? getLabelsByReservationStatus(status).color : 'gray'}
                >
                    <FunnelIcon width="16" height="16" />
                    Статус{`${status === null ? '' : `: ${getLabelsByReservationStatus(status).label}`}`}
                </Button>
            </Popover.Trigger>
            <Popover.Content maxWidth="80dvw">
                <Flex mt="1" align="center" gap="2" wrap="wrap">
                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.Cancelled).color}
                            variant={status === ReservationStatusString.Cancelled ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.Cancelled))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.Cancelled).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.Payed).color}
                            variant={status === ReservationStatusString.Payed ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.Payed))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.Payed).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.InReview).color}
                            variant={status === ReservationStatusString.InReview ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.InReview))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.InReview).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.ReadyForPayment).color}
                            variant={status === ReservationStatusString.ReadyForPayment ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.ReadyForPayment))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.ReadyForPayment).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.Pending).color}
                            variant={status === ReservationStatusString.Pending ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.Pending))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.Pending).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.Error).color}
                            variant={status === ReservationStatusString.Error ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.Error))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.Error).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color="gray"
                            variant={status === null ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.Payed))}
                        >
                            за замовчуванням
                        </Button>
                    </Popover.Close>

                </Flex>
            </Popover.Content>
        </Popover.Root>
    )
}
