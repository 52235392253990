import React, {useEffect} from "react";
import {Box, Flex, ScrollArea, Spinner, Text} from "@radix-ui/themes";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Dashboard from "../features/Dashboard/Dashboard";
import NotFound from "../features/NotFound/NotFound";
import BottomNav from "./BottomNav";

import Header from "./Header";
import PartnersManagement from "../features/PartnersManagement/PartnersManagement";
import ClientsList from "../features/Clients/ClientsList";
import ScanInfo from "../features/ScanInfo/ScanInfo";
import ReservationsList from "../features/Reservations/ReservationsList";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {RootState} from "../store/store";
import {getAuthStore, saveAuthStore} from "../helpers/utils";
import {noAuthToInitiate, reInitiateStore, setAuthProgress} from "../features/Login/authSlice";
import Login from "../features/Login/Login";
import ForgotPassword from "../features/Login/ForgotPassword";
import ProtectedRoute from "../helpers/ProtectedRoute";
import HRLogo from "../HR_Logo.svg";

import './layout.css'
import FreeSpotsView from "../features/FreeSpotsView/FreeSpotsView";
import PromoCodes from "../features/PromoCodes/PromoCodes";
import ExportReservations from "../features/ExportReservations/ExportReservations";

// todo: redirect to login if not logged in
export default function Layout() {

    // @ts-ignore
    const isFullScreen: boolean | null = window.navigator.standalone;

    const dispatch = useAppDispatch();

    const authentication = useAppSelector((state: RootState) => state.authentication);

    // store session on every change: logout, login, token refresh
    useEffect(() => {
        (async () => {
            if (!authentication.initiatedFromStorage) {
                return;
            }

            await saveAuthStore(authentication);
        })();
    }, [JSON.stringify(authentication)]);

    useEffect(() => {
        (async () => {
            const store = await getAuthStore();

            if (!store) {
                dispatch(noAuthToInitiate());
                setTimeout(() => dispatch(setAuthProgress(false)), 500)
                return;
            }

            dispatch(reInitiateStore(store));
            setTimeout(() => dispatch(setAuthProgress(false)), 500)
        })();
    }, []);

    if (authentication.authInProgress) {
        return (
            <Flex height="100dvh" direction="column" justify="center" align="center">
                <img src={HRLogo} height='64px' />
                <Flex mt="4" direction="column" align="center" gap="1">
                    <Spinner size="3" />
                    <Text size="1">Завантаження...</Text>
                </Flex>
            </Flex>
        )
    }

    return (
        <Flex
            direction="column"
            justify="between"
            height="100dvh"
            // position="relative"
        >
            <BrowserRouter>
                    <ScrollArea scrollbars="vertical" style={{
                        backgroundColor: 'var(--accent-1)',
                    }}>
                        <Header />
                        <Box px='4' style={{paddingBottom: isFullScreen ? 68+16 : 56+16}}>

                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />

                                <Route path="/" element={
                                    <ProtectedRoute>
                                        <Dashboard />
                                    </ProtectedRoute>
                                } />

                                <Route path="/partners-management" element={
                                    <ProtectedRoute>
                                        <PartnersManagement />
                                    </ProtectedRoute>
                                } />

                                <Route path="/promocodes" element={
                                    <ProtectedRoute>
                                        <PromoCodes />
                                    </ProtectedRoute>
                                } />

                                <Route path="/export-reservations" element={
                                    <ProtectedRoute>
                                        <ExportReservations />
                                    </ProtectedRoute>
                                } />

                                <Route path="/free-spots-view" element={
                                    <ProtectedRoute>
                                        <FreeSpotsView />
                                    </ProtectedRoute>
                                } />

                                <Route path="/reservations-list" element={
                                    <ProtectedRoute>
                                        <ReservationsList />
                                    </ProtectedRoute>
                                } />

                                {/*<Route path="/payments-list" element={*/}
                                {/*    <ProtectedRoute>*/}
                                {/*        <PaymentsList />*/}
                                {/*    </ProtectedRoute>*/}
                                {/*} />*/}

                                <Route path="/clients" element={
                                    <ProtectedRoute>
                                        <ClientsList />
                                    </ProtectedRoute>
                                } />

                                <Route path="/scan-result/:userID" element={
                                    <ProtectedRoute>
                                        <ScanInfo />
                                    </ProtectedRoute>
                                } />

                                <Route path="*" element={<NotFound />} />
                            </Routes>

                        </Box>
                    </ScrollArea>

                {authentication.isUserLoggedIn && <BottomNav />}

            </BrowserRouter>
        </Flex>
    )
}
