import React from "react";
import {Customer} from "./types";
import {Button, DataList, Dialog, Flex} from "@radix-ui/themes";
import {closeDeleteModal} from "../PartnersManagement/partnersSlice";

interface ViewCustomerModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    customer: Customer | null,
}

export default function ViewCustomerModal(props: ViewCustomerModalProps) {

    const { open, setOpen, customer} = props;

    if (! customer) return null;

    return (
        <Dialog.Root open={open}>
            <Dialog.Content>
                <Dialog.Title>Перегляд клієнта</Dialog.Title>

                <DataList.Root my="6">
                    <DataList.Item align="center">
                        <DataList.Label minWidth="88px">#</DataList.Label>
                        <DataList.Value>
                            {customer.id}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item align="center">
                        <DataList.Label minWidth="88px">Імʼя</DataList.Label>
                        <DataList.Value>
                            {customer.firstName}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item align="center">
                        <DataList.Label minWidth="88px">Прізвище</DataList.Label>
                        <DataList.Value>
                            {customer.lastName}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item align="center">
                        <DataList.Label minWidth="88px">Номер</DataList.Label>
                        <DataList.Value>
                            {customer.phone}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item align="center">
                        <DataList.Label minWidth="88px">E-mail</DataList.Label>
                        <DataList.Value>
                            {customer.email}
                        </DataList.Value>
                    </DataList.Item>
                </DataList.Root>

                <Flex gap="3" mt="4" justify="end">
                    <Button variant="soft" color="gray" onClick={() => setOpen(false)}>
                        Закрити
                    </Button>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    )
}
