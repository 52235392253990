import { emptySplitApi } from '../../api/api';
import {GetProfileRequest, GetProfileResponse} from "./types";

export const profileAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // will return empty object for admin login
    getProfile: builder.query<GetProfileResponse, null>({
      query() {
        return {
          url: '/customerProfile',
          method: 'GET',
        };
      },
      providesTags: ['CustomerProfile'],
    }),
    getProfileById: builder.query<GetProfileResponse, {userId: string}>({
      query(params) {
        return {
          url: `/customerProfile/${params.userId}`,
          method: 'GET',
        };
      },
      // todo: maybe bcz of this profile tag must be different
      providesTags: (result, error, arg) => [
        { type: 'CustomerProfile', ...arg },
      ],
    }),

    updateProfile: builder.mutation<null, GetProfileRequest>({
      query(data) {
        return {
          url: '/customerProfile',
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: () => ['CustomerProfile'],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetProfileByIdQuery,
  useUpdateProfileMutation
} = profileAPI;
