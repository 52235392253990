import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Services} from "../../types/services";
import {GetExportServicesRequest} from "./types";
import {ReservationStatusString} from "../Reservations/types";

interface ExportReservationSlice extends GetExportServicesRequest {}

const today = new Date();
const todayString = today.toISOString().split('T')[0];

const initialState: ExportReservationSlice = {
    status: ReservationStatusString.Payed,
    services: [],
    dateFrom: todayString,
    dateTo: todayString,
};

export const exportReservationsSlice = createSlice({
    name: 'exportReservations',
    initialState,
    reducers: {
        setDateFrom: (state, action: PayloadAction<string>) => {
            state.dateFrom = action.payload;
        },
        setDateTo: (state, action: PayloadAction<string>) => {
            state.dateTo = action.payload;
        },
        setStatus: (state, action: PayloadAction<ReservationStatusString>) => {
            state.status = action.payload;
        },
        resetServices: (state) => {
            state.services = [];
        },
        setService: (state, action: PayloadAction<{ service: Services, action: 'add' | 'remove'}>) => {
            if (action.payload.action === "add") {
                state.services = [...state.services, action.payload.service];
                return;
            }

            // remove
            state.services = state.services.filter(s => s !== action.payload.service)
        },

    }
});

export const {
    setStatus,
    setDateFrom,
    setDateTo,
    resetServices,
    setService,
} = exportReservationsSlice.actions;

export default exportReservationsSlice;
