import {createSlice, isAllOf, PayloadAction} from '@reduxjs/toolkit';
import {loginAPI} from './loginAPI';
import {LOGOUT_INTERCEPT, setNewTokensIntercept} from '../../api/refreshTokenActions';

// todo: determine role
export interface AuthState {
    // username: string;
    refreshToken: string;
    token: string;
    isUserLoggedIn: boolean;
    authInProgress: boolean;

    initiatedFromStorage: boolean;
}

// note: will be persisted in secure storage
const initialState: AuthState = {
    refreshToken: '',
    token: '',
    isUserLoggedIn: false,
    authInProgress: true,

    initiatedFromStorage: false,
};

export const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        noAuthToInitiate: (state) => {
            state.initiatedFromStorage = true;
        },
        setAuthProgress: (state, action: PayloadAction<boolean>) => {
            state.authInProgress = action.payload;
        },
        reInitiateStore: (state, action: PayloadAction<AuthState>) => {
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken;
            state.isUserLoggedIn = action.payload.isUserLoggedIn;
            state.initiatedFromStorage = true;
        },
        logoutUser: (state) => {
            state.token = '';
            state.refreshToken = '';
            state.isUserLoggedIn = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(LOGOUT_INTERCEPT, (state) => {
                state.token = '';
                state.isUserLoggedIn = false;
            })
            .addMatcher(isAllOf(setNewTokensIntercept), (state, { payload }) => {
                if (!payload) {
                    return;
                }

                state.token = payload.accessToken;
                state.refreshToken = payload.refreshToken;
            })
            .addMatcher(loginAPI.endpoints.loginUser.matchFulfilled, (state, { payload }) => {
                if (payload.exception === null) {
                    state.refreshToken = payload.refreshToken;
                    state.token = payload.accessToken;
                    state.isUserLoggedIn = true;
                }
            });
    },
});

export const {
    logoutUser,
    reInitiateStore,
    noAuthToInitiate,
    setAuthProgress,
} = authSlice.actions;

export default authSlice;
