import React from "react";
import {useGetPromoCodesListQuery} from "./promoCodesAPI";
import {Flex, IconButton, Spinner, Table} from "@radix-ui/themes";
import {formatDateToISO} from "../../helpers/dateFormat";
import {EyeIcon} from "@heroicons/react/24/outline";
import {useAppDispatch} from "../../store/hooks";
import {initializeFormForEdit} from "./promoCodeSlice";

export default function PromoCodesList() {
    const {data: promoCodes, isLoading} = useGetPromoCodesListQuery(null);

    const dispatch = useAppDispatch();

    return (
        <>
            <Flex justify="center">
                {
                    (isLoading) && <Flex justify="center" mt="2"><Spinner size="3" /> </Flex>
                }
            </Flex>
            <Table.Root>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell>Код</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Знижка</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>До</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Дія</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {
                    promoCodes?.map((promoCode) => (
                        <Table.Row key={promoCode.code}>
                            <Table.RowHeaderCell>{promoCode.code}</Table.RowHeaderCell>
                            <Table.RowHeaderCell>
                                {promoCode.discountAmount}{' '}грн
                            </Table.RowHeaderCell>
                            <Table.Cell>{formatDateToISO(new Date(promoCode.expirationDate)).split('T')[0]}</Table.Cell>
                            <Table.Cell>
                                <Flex gap="2">
                                    <IconButton
                                        onClick={() => {
                                            dispatch(initializeFormForEdit({
                                                ...promoCode,
                                                expirationDate: promoCode.expirationDate.split('T')[0]
                                            }))
                                        }}
                                    >
                                        <EyeIcon width="16px" height="16px" />
                                    </IconButton>
                                </Flex>
                            </Table.Cell>
                        </Table.Row>
                    ))
                }
                </Table.Body>
            </Table.Root>
        </>
    )
}
