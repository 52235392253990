import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileSlice {
    email: string,
    password: string,
    firstName: string,
    lastName: string,
}

// note: will be persisted in secure storage
const initialState: ProfileSlice = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload
        },
    },
});

export const {
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
} = profileSlice.actions;

export default profileSlice;
