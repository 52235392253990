import React, {ReactElement} from "react";
import {Box, Card, Flex, Text} from "@radix-ui/themes";
import Dancing from "../images/dancing.png";
import {BoxProps} from "@radix-ui/themes/src/components/box";
import {useNavigate} from "react-router-dom";

interface DashboardFeatureProps {
    containerProps: BoxProps,
    title: string,
    description?: string,
    icon: ReactElement,
    rotateOpposite?: true,
    column?: boolean,
    linkTo?: string,
    disabled?: boolean,
}
export default function DashboardFeature(props: DashboardFeatureProps) {

    const {
        containerProps,
        title,
        description,
        icon,
        column,
        linkTo = '/',
        disabled
    } = props;

    const navigate = useNavigate();

    return (
        <Box
            {...containerProps}
            className="dashboard-feature"
            onClick={() => {
                if (disabled) return;
                navigate(linkTo);
            }}
        >
            <Card size='3' variant="classic" className="dashboard-feature__card">
                <Flex gap="3" align="center" justify="between" direction={column ? 'column-reverse' : undefined}>
                    <Box>
                        <Text as="div" size="4" weight="bold">
                            { title }
                        </Text>
                        {
                            description && (
                                <Text as="div" size="1" color="gray">
                                    { description }
                                </Text>
                            )
                        }
                    </Box>
                    { icon }
                </Flex>
            </Card>
        </Box>
    )
}
