import React from "react";
import {Button, DataList, Dialog, Flex} from "@radix-ui/themes";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {RootState} from "../../store/store";
import {closeDeleteModal} from "./partnersSlice";
import {useDeletePartnerMutation} from "./partnersAPI";

// todo: actually delete partner
export default function DeletePartnerModal() {
    const {
        deleteModalOpen,
        partner,
    } = useAppSelector((store: RootState) => store.partnersManagement.deleteModal);

    const dispatch = useAppDispatch()

    const [deletePartnerRequest, { isLoading, isError }] = useDeletePartnerMutation();

    const onDelete = async () => {
        if (!partner || ! partner.id) return;

        await deletePartnerRequest({ userId: partner.id});

        dispatch(closeDeleteModal());
    }

    return (
        <Dialog.Root open={deleteModalOpen}>
            <Dialog.Content>
                <Dialog.Title>Видалити партнера</Dialog.Title>

                <DataList.Root my="6">
                    <DataList.Item align="center">
                        <DataList.Label minWidth="88px">Імʼя</DataList.Label>
                        <DataList.Value>
                            {partner?.firstName}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item align="center">
                        <DataList.Label minWidth="88px">Прізвище</DataList.Label>
                        <DataList.Value>
                            {partner?.lastName}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item align="center">
                        <DataList.Label minWidth="88px">Номер</DataList.Label>
                        <DataList.Value>
                            {partner?.phone}
                        </DataList.Value>
                    </DataList.Item>
                </DataList.Root>

                <Flex gap="3" mt="4" justify="end">
                    <Dialog.Close>
                        <Button variant="soft" color="gray" onClick={() => dispatch(closeDeleteModal())}>
                            Назад
                        </Button>
                    </Dialog.Close>
                    <Dialog.Close>
                        <Button color="red" onClick={onDelete}>Видалити</Button>
                    </Dialog.Close>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
}
