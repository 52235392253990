import {Button, Flex, Popover} from "@radix-ui/themes";
import React from "react";
import {FunnelIcon} from "@heroicons/react/16/solid";
import {setStatus} from "./reservationsSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {RootState} from "../../store/store";
import {getLabelsByReservationStatus} from "./helpers";
import {ReservationStatusString} from "./types";
import {Roles} from "../../types/roles";
import {useGetProfileQuery} from "../Profile/profileAPI";

export default function ReservationsFilterStatus() {

    const { status } = useAppSelector((state: RootState) => state.reservations);

    const dispatch = useAppDispatch();

    const { data: profile } = useGetProfileQuery(null);

    return (
        <Popover.Root>
            <Popover.Trigger>
                <Button
                    variant="soft"
                    size="1"
                    mt="1"
                    color={status !== null ? getLabelsByReservationStatus(status).color : 'gray'}
                >
                    <FunnelIcon width="16" height="16" />
                    Статус{`${status === null ? '' : `: ${getLabelsByReservationStatus(status).label}`}`}
                </Button>
            </Popover.Trigger>
            <Popover.Content maxWidth="80dvw">
                <Flex mt="1" align="center" gap="2" wrap="wrap">
                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.Cancelled).color}
                            variant={status === ReservationStatusString.Cancelled ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.Cancelled))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.Cancelled).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.Payed).color}
                            variant={status === ReservationStatusString.Payed ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.Payed))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.Payed).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.InReview).color}
                            variant={status === ReservationStatusString.InReview ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.InReview))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.InReview).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.ReadyForPayment).color}
                            variant={status === ReservationStatusString.ReadyForPayment ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.ReadyForPayment))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.ReadyForPayment).label }
                        </Button>
                    </Popover.Close>

                    <Popover.Close>
                        <Button
                            size="1"
                            color={getLabelsByReservationStatus(ReservationStatusString.Pending).color}
                            variant={status === ReservationStatusString.Pending ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(ReservationStatusString.Pending))}
                        >
                            { getLabelsByReservationStatus(ReservationStatusString.Pending).label }
                        </Button>
                    </Popover.Close>

                    {
                        (profile && profile.role === Roles.Admin) ? (
                            <Popover.Close>
                                <Button
                                    size="1"
                                    color={getLabelsByReservationStatus(ReservationStatusString.Error).color}
                                    variant={status === ReservationStatusString.Error ? 'surface' : 'outline'}
                                    onClick={() => dispatch(setStatus(ReservationStatusString.Error))}
                                >
                                    { getLabelsByReservationStatus(ReservationStatusString.Error).label }
                                </Button>
                            </Popover.Close>
                        ) : null
                    }

                    <Popover.Close>
                        <Button
                            size="1"
                            color="gray"
                            variant={status === null ? 'surface' : 'outline'}
                            onClick={() => dispatch(setStatus(null))}
                        >
                            Прибрати фільтр
                        </Button>
                    </Popover.Close>

                </Flex>
            </Popover.Content>
        </Popover.Root>
    )
}
