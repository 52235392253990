import {Button, CheckboxGroup, Flex, Popover, ScrollArea} from "@radix-ui/themes";
import React, {useEffect, useState} from "react";
import {FunnelIcon} from "@heroicons/react/16/solid";
import {resetServices, setService} from "./reservationsSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {RootState} from "../../store/store";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {getServiceNameByEnum, Services} from "../../types/services";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {Roles} from "../../types/roles";

export default function ReservationsFilterByService() {

    const [servicesEnumKeys, setServicesEnumKeys] = useState<string[]>([]);

    const { services } = useAppSelector((state: RootState) => state.reservations);
    const {data: profile} = useGetProfileQuery(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (! profile) return;

        const fullList = Object.keys(Services).filter(key => isNaN(Number(key)));
        if (profile && profile.role === Roles.Admin) {
            setServicesEnumKeys(fullList);
            return;
        }

        // filter for managers
        setServicesEnumKeys(
            fullList.filter((key) => {

                if (! profile.services) return false;

                // @ts-expect-error
                return profile.services.includes(Services[key]);
            })
        )
    }, [profile]);

    return (
        <Popover.Root>
            <Popover.Trigger>
                <Button
                    variant="soft"
                    size="1"
                    mt="1"
                    color={services.length > 0 ? 'amber' : 'gray'}
                >
                    <FunnelIcon width="16" height="16" />
                    Послуги{`${services.length === 0 ? '' : `: ${services.length}`}`}
                </Button>
            </Popover.Trigger>
            <Popover.Content maxWidth="95dvw">
                <ScrollArea type="always" scrollbars="vertical" style={{ maxHeight: '50dvh'}}>
                    <Flex mt="1" direction="column" gap="2">
                        {
                            profile && profile.role === Roles.Admin && (
                                <Flex gap="2">
                                    <Popover.Close>
                                        <Button
                                            size="1"
                                            color="gray"
                                            variant={services.length > 0 ? 'surface' : 'outline'}
                                            onClick={() => dispatch(resetServices())}
                                        >
                                            <XCircleIcon width="16" height="16" /> Прибрати фільтри
                                        </Button>
                                    </Popover.Close>
                                    <Popover.Close>
                                        <Button
                                            size="1"
                                            color="gray"
                                            variant={services.length > 0 ? 'surface' : 'outline'}
                                            // onClick={() => dispatch(resetServices())}
                                        >
                                            <XCircleIcon width="16" height="16" /> Закрити
                                        </Button>
                                    </Popover.Close>
                                </Flex>
                            )
                        }

                        <CheckboxGroup.Root size="2" name="services" value={services.map(s => s.toString())}>
                            {
                                servicesEnumKeys.map((key: string) => (
                                    // @ts-ignore
                                    <CheckboxGroup.Item
                                        key={key}
                                        // @ts-expect-error
                                        value={Services[key].toString()}
                                        onClick={(e) => {
                                            dispatch(setService({
                                                // @ts-expect-error
                                                service: Services[key],
                                                // @ts-expect-error
                                                action: services.includes(Services[key]) ? 'remove' : 'add',
                                            }));
                                        }}
                                    >
                                        {/*@ts-expect-error */}
                                        { getServiceNameByEnum(Services[key])}
                                    </CheckboxGroup.Item>
                                ))
                            }
                        </CheckboxGroup.Root>

                        <Flex gap="2">
                            <Popover.Close>
                                <Button
                                    size="1"
                                    color="gray"
                                    variant={services.length > 0 ? 'surface' : 'outline'}
                                    onClick={() => dispatch(resetServices())}
                                >
                                    <XCircleIcon width="16" height="16" /> Прибрати фільтри
                                </Button>
                            </Popover.Close>
                            <Popover.Close>
                                <Button
                                    size="1"
                                    color="gray"
                                    variant={services.length > 0 ? 'surface' : 'outline'}
                                    // onClick={() => dispatch(resetServices())}
                                >
                                    <XCircleIcon width="16" height="16" /> Закрити
                                </Button>
                            </Popover.Close>
                        </Flex>

                    </Flex>
                </ScrollArea>
            </Popover.Content>
        </Popover.Root>
    )
}
