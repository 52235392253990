import React from "react";
import {Blockquote, Box, Flex, Heading, Text} from "@radix-ui/themes";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {useParams} from "react-router-dom";
import {useGetReservationsByUserQuery} from "../Reservations/reservationsAPI";
import {useGetProfileByIdQuery} from "../Profile/profileAPI";
import {formatDateToISO} from "../../helpers/dateFormat";
import {useGetAccessZonesQuery} from "./scanInfoAPI";
import {getQuantityLabelByServiceEnum, getServiceNameByEnum} from "../../types/services";

export default function ScanInfo() {

    const { userID = '' } = useParams();

    const {data: accessZones} = useGetAccessZonesQuery({
        userId: userID,
        date: formatDateToISO(new Date()),
    });

    const {data: profile} = useGetProfileByIdQuery({ userId: userID });

    const checkCircleIcon = <CheckCircleIcon width="16px" height="16px" color="teal" />;
    const xCircleIcon = <XCircleIcon width="16px" height="16px" color="red" />;

    return (
        <Flex direction='column' pt='4'>
            <Heading>Скан інфо</Heading>

            <Box mt="4">
                <Text>Відвідувач: <b>{profile?.firstName} {profile?.lastName}</b></Text>

                <Box mt="4">
                    <Text size="2" weight="bold">Доступ сьогодні</Text>
                    <Blockquote>
                        <Flex direction="row" align="center" gap="1">
                            {accessZones?.accessZones.includes(1) ? checkCircleIcon : xCircleIcon }
                            Зона 1: <b>{accessZones?.accessZones.includes(1) ? 'дозволено' : 'заборонено'}</b>
                        </Flex>
                    </Blockquote>
                    <Blockquote>
                        <Flex direction="row" align="center" gap="1">
                            {accessZones?.accessZones.includes(2) ? checkCircleIcon : xCircleIcon }
                            Зона 2: <b>{accessZones?.accessZones.includes(2) ? 'дозволено' : 'заборонено'}</b>
                        </Flex>
                    </Blockquote>
                    <Blockquote>
                        <Flex direction="row" align="center" gap="1">
                            {accessZones?.accessZones.includes(3) ? checkCircleIcon : xCircleIcon }
                            Зона 3: <b>{accessZones?.accessZones.includes(3) ? 'дозволено' : 'заборонено'}</b>
                        </Flex>

                    </Blockquote>
                </Box>

                <Box mt="4">
                    <Text size="2" weight="bold">Активні бронювання на сьогодні</Text>
                    {
                        accessZones?.reservations.map(reservation => (
                            <Blockquote key={reservation.id}>
                                {getServiceNameByEnum(reservation.serviceId)} <br />
                                Кількість заброньованих {getQuantityLabelByServiceEnum(reservation.serviceId)}: {reservation.numberOfSpots}
                            </Blockquote>
                        ))
                    }
                </Box>
            </Box>
        </Flex>
    )
}
