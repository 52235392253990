import {Services} from "../../types/services";
import {Customer} from "../Clients/types";

export enum ReservationStatusString {
    Cancelled = 'Cancelled',
    Payed = 'Payed',
    InReview = 'InReview',
    ReadyForPayment = 'ReadyForPayment',
    Pending = 'Pending',
    Error = 'Error',
}

export interface GetReservationsListRequest {
    pageNumber: number,
    pageSize: number,
    status?: ReservationStatusString | null,
    date: string | null,
    phone: string | null,
    reservationId: number | null,
    services: Services[],
}

export interface GetReservationsListResponse {
    totalCount: number,
    reservations: Reservation[]
}

export interface Reservation {
    id: number,
    serviceId: number,
    dateFrom: string,
    dateTo: string | null,
    createdAt: string,
    customer?: Customer | null, // optional so no need to update all places where type is used
    numberOfSpots: number,
    amount: number | null,
    status: ReservationStatusString, // but string "InReview"
    promoCode: ActivePromoCodeResponse | null,
}

export interface BookingRequestPayload {
    serviceId: Services,
    dateFrom: string,
    dateTo?: string | null,
    numberOfSpots: number,
    amount: number,
    userID?: number,
}

export interface UpdateReservationRequest {
    reservationId: number,
    dateFrom: string,
    dateTo?: string,
    numberOfSpots: number,
    amount: number,
    status: string,
    comment?: string,
}

// note todo: https://trello.com/c/0sH0DBPP/241-get-activepromocode-minor-fixes
export interface ActivePromoCodeResponse {
    code: string,
    discountAmount: number,
    expirationDate: string,
}
