import React from "react";
import {Blockquote, Box, Flex} from "@radix-ui/themes";
import QRCode from "react-qr-code";
import {getServiceNameByEnum} from "../../../types/services";
import {Reservation} from "../types";
import {CLIENT_APP_URL} from "../../../api/config";

interface PaymentQRProps {
    reservation: Reservation | null,
}

export default function PaymentQR(props: PaymentQRProps) {

    const { reservation } = props;

    if (! reservation) return null;

    return (
        <Box>
            <Flex p="4" style={{ backgroundColor: '#fff' }}>
                <QRCode
                    value={`${CLIENT_APP_URL}/link-payment/${reservation.id}`}
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                />
            </Flex>
            <Box mt="2">
                <Blockquote>
                    {getServiceNameByEnum(reservation.serviceId)}
                </Blockquote>
                <Blockquote>
                    {reservation.amount}грн
                </Blockquote>
            </Box>
        </Box>
    )
}
