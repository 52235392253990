import React, {useEffect, useState} from 'react';
import {Theme, ThemePanel} from '@radix-ui/themes';
import Layout from "./Layout/Layout";

import '@radix-ui/themes/styles.css';
import './index.css';


function App() {
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  useEffect(() => {
    window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', e => setMode(e.matches ? 'dark' : 'light'))
    ;

    setMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, []);

  return (
      <Theme
          accentColor="orange"
          grayColor="mauve"
          appearance={mode}
          radius="medium"
          panelBackground="translucent"
      >
        <Layout />
        {/*<ThemePanel />*/}
      </Theme>
  );
}

export default App;
