import { emptySplitApi } from '../../api/api';
import {GetSpotsResponse, UpdateSpotsRequest} from "./types";

export const dashboardAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getSpotsConfiguration: builder.query<GetSpotsResponse, null>({
            query() {
                return {
                    url: '/spotsConfiguration',
                };
            },
            providesTags: ['SpotsConfiguration'],
        }),
        updateSpots: builder.mutation<null, UpdateSpotsRequest>({
            query(data) {
                return {
                    url: '/spotsConfiguration',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: () => ['SpotsConfiguration', 'SpotsCalculation'],
        }),
        getSpotsCalculation: builder.query<GetSpotsResponse, { date?: string }>({
            query(params) {
                return {
                    url: '/calcSpotsConfiguration',
                    params,
                };
            },
            providesTags: (result, error, arg) => [
                { type: 'SpotsCalculation', ...arg },
            ],
        }),
    }),
});

export const {
    useGetSpotsConfigurationQuery,
    useUpdateSpotsMutation,
    useGetSpotsCalculationQuery,
} = dashboardAPI;
