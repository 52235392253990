import {Button, Dialog, Flex, Link, Text, TextField} from "@radix-ui/themes";
import React, {useEffect, useState} from "react";
import {getServiceNameByEnum, getServiceType, ServiceType} from "../../types/services";
import {Reservation, ReservationStatusString, UpdateReservationRequest} from "./types";
import FreeSpots from "./components/FreeSpots";
import {getPriceAmount} from "../../helpers/prices";
import {useUpdateReservationMutation} from "./reservationsAPI";
import PaymentQR from "./components/PaymentQR";
import {format} from "@react-input/mask";
import {maskFormatConfig} from "../../helpers/mask";

interface ReservationReviewModalProps {
    reservation: Reservation | null,
    open: boolean,
    setOpen: (open: boolean) => void
}
export default function ReservationReviewModal(props: ReservationReviewModalProps) {

    const { open, setOpen, reservation } = props;

    const [showQR, setShowQR] = useState(false);
    const [calculatedPrice, setCalculatedPrice] = useState(0);

    /* form fields */
    const [amount, setAmount] = useState<'' | number>(0);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [comment, setComment] = useState('');
    const [numberOfSpots, setNumberOfSpots] = useState(1);

    const [updateReservationRequest, { isLoading, isError }] = useUpdateReservationMutation();

    const isHotelBooking = getServiceType(reservation ? reservation.serviceId : null) === ServiceType.ACCOMMODATION;
    const allowEdit = reservation?.status === ReservationStatusString.InReview;

    useEffect(() => {
        if (! reservation || !dateFrom) {
            setCalculatedPrice(0);
            return;
        }

        const price = getPriceAmount(
            reservation.serviceId,
            numberOfSpots,
            new Date(dateFrom),
            dateTo ? new Date(dateTo) : undefined
        );

        setCalculatedPrice(price);
    }, [reservation, numberOfSpots, dateFrom, dateTo]);

    useEffect(() => {
        if (! reservation) return;

        setAmount(reservation.amount || 0);

        setNumberOfSpots(reservation.numberOfSpots);

        if (! isHotelBooking) {
            if (reservation.dateTo) {
                setDateTo(reservation.dateTo);
            } else {
                setDateTo('');
            }

            setDateFrom(reservation.dateFrom);
        } else {
            if (reservation.dateTo) {
                setDateTo(reservation.dateTo.split('T')[0]);
            }

            setDateFrom(reservation.dateFrom.split('T')[0]);
        }

    }, [reservation, reservation?.id, isHotelBooking]);

    const onSubmit = async (status: ReservationStatusString) => {
        if (! dateFrom) return;
        if (! reservation) return;
        if ((! amount) && status === ReservationStatusString.ReadyForPayment) return;

        let payload: UpdateReservationRequest = {
            reservationId: reservation.id,
            dateFrom,
            numberOfSpots,
            amount: amount || 0,
            status,
            comment,
        }

        if (isHotelBooking) {
            payload.dateTo = dateTo;
        }

        const response = await updateReservationRequest(payload);

        // @ts-expect-error
        if (! response.error) {
            setOpen(false);
        }
    }

    if (! reservation) return null;

    return (
        <Dialog.Root open={open}>
            <Dialog.Content onOpenAutoFocus={(e) => e.preventDefault()} maxWidth="740px">
                <Dialog.Title>Підтвердження запису</Dialog.Title>

                <Dialog.Description size="2" mb="4">
                    <Text size="2" weight="bold">Послуга: {getServiceNameByEnum(reservation.serviceId)}</Text> <br />
                    {
                        reservation.customer && (
                            <>
                                <Text size="2">{`${reservation.customer.firstName} ${reservation.customer.lastName}`}</Text> <br />
                                <Link href={`tel:${reservation.customer.phone}`}>
                                    {format(reservation.customer.phone, maskFormatConfig)}
                                </Link>
                            </>
                        )
                    }
                </Dialog.Description>

                <Flex direction="column" gap="4">

                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            Кількість
                        </Text>
                        <TextField.Root
                            type='number'
                            value={numberOfSpots}
                            disabled={! allowEdit}
                            min={1}
                            onChange={(e) => setNumberOfSpots(parseInt(e.target.value))}
                        />
                    </label>
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            { isHotelBooking ? 'Дата заїзду' : 'Час і дата'}
                        </Text>
                        <TextField.Root
                            type={ isHotelBooking ? 'date' : 'datetime-local'}
                            value={dateFrom}
                            disabled={! allowEdit}
                            min={
                                isHotelBooking
                                    ? new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":")).split('T')[0]
                                    : new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":"))
                            }
                            onChange={(e) => setDateFrom(e.target.value)}
                            step={isHotelBooking ? undefined : '15'}
                        />
                    </label>
                    {
                        isHotelBooking && (
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    Дата виїзду
                                </Text>
                                <TextField.Root
                                    type="date"
                                    value={dateTo}
                                    disabled={! allowEdit}
                                    min={new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":")).split('T')[0]}
                                    onChange={(e) => setDateTo(e.target.value)}
                                    step={isHotelBooking ? undefined : '15'}
                                />
                            </label>
                        )
                    }
                    <FreeSpots service={reservation.serviceId} date={dateFrom} />
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            Сума
                        </Text>
                        <TextField.Root
                            type="number"
                            disabled={! allowEdit}
                            placeholder="XXX грн"
                            value={amount}
                            onChange={(event) => {
                                const amount = event.target.value ? parseInt(event.target.value) : ''
                                setAmount(amount);
                            }}
                        />
                        <Flex direction="column">
                            <Text size="1">
                                {reservation.promoCode ? 'Ціна без знижки' : 'Розрахована ціна:'} <b>{calculatedPrice}грн</b>
                            </Text>

                            {
                                reservation.promoCode ? (
                                    <Flex direction="column">
                                        <Text size="1">Промокод: <b>{reservation.promoCode.code}</b> </Text>
                                        <Text size="1">Знижка: <b>-{reservation.promoCode.discountAmount}грн</b> </Text>
                                    </Flex>
                                ) : null
                            }
                        </Flex>
                    </label>
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            Коментар для відвідувача
                        </Text>
                        <TextField.Root
                            type='text'
                            disabled={! allowEdit}
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                        />
                    </label>
                </Flex>

                {
                    (! reservation.customer) && showQR && (
                        <Flex my="4">
                            <PaymentQR reservation={reservation} />
                        </Flex>
                    )
                }

                <Flex gap="2" mt="6" justify="between" align="center">
                    <Button variant="soft" color="gray" onClick={() => setOpen(false)}>
                        Назад
                    </Button>
                    {
                        (! reservation.customer) && (
                            <Button variant="soft" onClick={() => setShowQR(! showQR)}>
                                {showQR ? 'Приховати' : 'Показати'} QR для оплат
                            </Button>
                        )
                    }
                    <Flex gap="2" display={allowEdit ? 'flex' : 'none'}>
                        <Button
                            color="red"
                            onClick={() => onSubmit(ReservationStatusString.Cancelled)}
                            disabled={isLoading || (!allowEdit)}
                        >
                            Відхилити
                        </Button>
                        <Button
                            onClick={() => onSubmit(ReservationStatusString.ReadyForPayment)}
                            disabled={isLoading || (!allowEdit)}
                        >
                            Підтвердити
                        </Button>
                    </Flex>
                </Flex>
                <Flex justify="end" mt="1">
                    {
                        (isError) && <Text size="1" color="red">Сталася помилка. Спробуйте знову</Text>
                    }
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    )
}
