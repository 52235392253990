import React from "react";
import {Box, Button, Flex, Heading} from "@radix-ui/themes";
import {BookOpenIcon, ReceiptPercentIcon, UserCircleIcon, TableCellsIcon, UserGroupIcon} from "@heroicons/react/24/outline";
import DashboardFeature from "./components/DashboardFeature";

import './index.css'
import {useNavigate} from "react-router-dom";
import ManageParking from "./ManageParking";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {ArrowLeftEndOnRectangleIcon} from "@heroicons/react/16/solid";
import {logoutUser} from "../Login/authSlice";
import {useAppDispatch} from "../../store/hooks";
import {Roles} from "../../types/roles";
import FreeSpotsDashboardViewForManagers from "../FreeSpotsView/FreeSpotsDashboardViewForManagers";


export default function Dashboard() {

    const navigate = useNavigate();

    const {data: profile} = useGetProfileQuery(null);

    const dispatch = useAppDispatch();

    const onLogout = () => {
        dispatch(logoutUser());
        navigate('/');
    };

    return (
        <Flex direction="column" py='2'>
            <Heading>Вітаємо, {profile?.firstName}</Heading>

            {
                (profile && profile.role === Roles.Admin) ? (
                    <>
                        <DashboardFeature
                            containerProps={{mt: '4'}}
                            title="Партнери"
                            description="Переглянути, додати партнера (адміністратора напряму)"
                            linkTo="/partners-management"
                            icon={<UserCircleIcon width='60px' height='60px' />}
                        />

                        <DashboardFeature
                            containerProps={{mt: '4'}}
                            title="Клієнти"
                            description="Переглянути список відвідувачів, номери телефонів та емейли"
                            linkTo="/clients"
                            icon={<UserGroupIcon width='60px' height='60px' />}
                        />

                        <DashboardFeature
                            containerProps={{mt: '4'}}
                            title="Промокоди"
                            description="Переглянути список відвідувачів, номери телефонів та емейли"
                            linkTo="/promocodes"
                            icon={<ReceiptPercentIcon width='60px' height='60px' />}
                        />

                        <DashboardFeature
                            containerProps={{mt: '4'}}
                            title="Аналітика"
                            description="Експорт резервацій"
                            linkTo="/export-reservations"
                            icon={<TableCellsIcon width='60px' height='60px' />}
                        />

                        <Box mt="4">
                            <ManageParking />
                        </Box>
                    </>
                ) : (
                    <Box mt="4">
                        <FreeSpotsDashboardViewForManagers />
                    </Box>
                )
            }



            <DashboardFeature
                containerProps={{mt: '4'}}
                title="Резервації"
                description="Переглянути резервації"
                linkTo="/reservations-list"
                icon={<BookOpenIcon width='60px' height='60px' />}
            />

            <Flex mt="4" justify="end">
                <Button color="red" onClick={onLogout}>
                    <ArrowLeftEndOnRectangleIcon width="16" height="16" />
                    Вийти
                </Button>
            </Flex>

        </Flex>
    )
}
