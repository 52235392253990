import React, {useState} from "react";
import xslx from 'xlsx';
import {Button, Flex, Heading, Spinner, Text} from "@radix-ui/themes";

import ExportServicesFilterByService from "./filters/ExportServicesFilterByService";
import ExportServicesFilterByStatus from "./filters/ExportServicesFilterByStatus";
import ExportServicesFilterByDateTo from "./filters/ExportServicesFilterByDateTo";
import ExportServicesFilterByDateFrom from "./filters/ExportServicesFilterByDateFrom";
import {useAppSelector} from "../../store/hooks";
import {RootState} from "../../store/store";
import {useGetServicesExportMutation} from "./exportAPI";
import {prepareDataForExcelExport} from "./helpers";

export default function ExportReservationsView() {

    const [errorMessage, setErrorMessage] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const {
        services, dateTo, dateFrom, status
    } = useAppSelector((state: RootState) => state.exportReservations);

    const [getServicesRequest, { isLoading, isError, isSuccess }] = useGetServicesExportMutation();

    const onExport = async () => {
        if (services.length === 0) {
            setErrorMessage('Має бути обрана хоча б одна з послуг');
            return;
        }

        setErrorMessage('');

        const response = await getServicesRequest({ services, dateTo, dateFrom, status });

        // @ts-expect-error
        if (response.data) {
            // @ts-expect-error
            setTotalCount(response.data.length);

            // @ts-expect-error
            if (response.data.length === 0) return;

            // @ts-expect-error
            const worksheet = xslx.utils.json_to_sheet(prepareDataForExcelExport(response.data));

            const workbook = xslx.utils.book_new();
            xslx.utils.book_append_sheet(workbook, worksheet, `${dateFrom} - ${dateTo}`);

            xslx.writeFile(workbook, `Експорт резервацій Lake Resort ${dateFrom} - ${dateTo}.xlsx`, { compression: true });
        }
    }

    return (
        <>
            <Flex gap="1" wrap="wrap">
                <ExportServicesFilterByService />
                <ExportServicesFilterByStatus />
                <ExportServicesFilterByDateFrom />
                <ExportServicesFilterByDateTo />
            </Flex>

            <Flex direction="column" gap="1">
                <Button disabled={isLoading} onClick={onExport}>
                    {isLoading && <Spinner size="3" /> } Експортувати
                </Button>
                <Text size="1" color="red">{errorMessage}</Text>
                {
                    isError && <Text size="1" color="red">Серверна помилка. Спробуйте знову.</Text>
                }
            </Flex>
            {
                isSuccess && (
                    <Flex>
                        <Heading size="3">Загальна кількість: {totalCount}</Heading>
                    </Flex>
                )
            }
        </>
    );
}
