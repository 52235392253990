import {Callout, Flex, Heading} from "@radix-ui/themes";
import React, {useEffect} from "react";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {Roles} from "../../types/roles";
import {useNavigate} from "react-router-dom";
import PromoCodesList from "./PromoCodesList";
import {InformationCircleIcon} from "@heroicons/react/16/solid";
import PromoCodeFormModal from "./PromoCodeFormModal";

export default function PromoCodes() {

    const { data: profile } = useGetProfileQuery(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (profile && profile.role !== Roles.Admin) {
            navigate('/');
        }
    }, [profile]);

    return (
        <Flex direction='column' pt='4'>
            <Heading>Промокоди</Heading>

            {
                profile && profile.role === Roles.Admin && (
                    <Flex mt='4' direction='column' gap='4'>
                        <PromoCodeFormModal />

                        <PromoCodesList />
                    </Flex>
                )
            }
        </Flex>
    );
}
