import React from "react";
import {Box, Card, Flex, IconButton, Table, Text} from "@radix-ui/themes";
import {Services} from "../../types/services";
import FreeSpots from "../Reservations/components/FreeSpots";
import {useGetProfileQuery} from "../Profile/profileAPI";

export default function FreeSpotsDashboardViewForManagers() {
    const today = new Date();
    const todayString = today.toISOString();

    const { data: profile } = useGetProfileQuery(null);

    return (
        <Box className="dashboard-feature">
            <Card size='3' variant="classic" className="dashboard-feature__card">
                <Flex justify="between" direction="column">
                    <Text as="div" size="4" weight="bold">
                        Вільні місця
                    </Text>
                    <Text as="div" size="1" color="gray">
                        Кількість доступних місць на сьогодні
                    </Text>

                    <Table.Root size="1" mt="4">
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Послуга</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>К-сть</Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                profile?.services?.includes(Services.SUN_LOUNGERS) ? (
                                    <Table.Row>
                                        <Table.RowHeaderCell>Шезлонги</Table.RowHeaderCell>
                                        <Table.Cell>
                                            <FreeSpots service={Services.SUN_LOUNGERS} date={todayString} onlyNumber />
                                        </Table.Cell>
                                    </Table.Row>
                                ) :  null
                            }

                            {
                                profile?.services?.includes(Services.SUN_LOUNGERS_WITH_TABLE) ? (
                                    <Table.Row>
                                        <Table.RowHeaderCell>Шезлонги зі столиком</Table.RowHeaderCell>
                                        <Table.Cell>
                                            <FreeSpots service={Services.SUN_LOUNGERS_WITH_TABLE} date={todayString} onlyNumber />
                                        </Table.Cell>
                                    </Table.Row>
                                ) :  null
                            }

                            {
                                profile?.services?.includes(Services.MINIPOOL) ? (
                                    <Table.Row>
                                        <Table.RowHeaderCell>Шезлонги біля басейну</Table.RowHeaderCell>
                                        <Table.Cell>
                                            <FreeSpots service={Services.MINIPOOL} date={todayString} onlyNumber />
                                        </Table.Cell>
                                    </Table.Row>
                                ) : null
                            }

                        </Table.Body>
                    </Table.Root>
                </Flex>
            </Card>
        </Box>
    );
}
