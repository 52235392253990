import React, {useEffect, useState} from "react";
import {Box, Button, Card, Flex, Text, TextField} from "@radix-ui/themes";
import {useGetSpotsConfigurationQuery, useUpdateSpotsMutation} from "./dashboardAPI";
import FreeSpots from "../Reservations/components/FreeSpots";
import {Services} from "../../types/services";
import {useNavigate} from "react-router-dom";

export default function ManageParking() {

    const [loungers, setLoungers] = useState(0);
    const [loungersWithTable, setLoungersWithTable] = useState(0);
    const [minipool, setMinipool] = useState(0);
    const [entryTickets, setEntryTickets] = useState(0);

    const { data: spots, isLoading, isError} = useGetSpotsConfigurationQuery(null);
    const [updateSpotsRequest, { isLoading: isUpdating, isError: isErrorUpdating}] = useUpdateSpotsMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (! spots) return;

        setMinipool(spots.numberOfMinipool);
        setLoungers(spots.numberOfSunLoungers);
        setLoungersWithTable(spots.numberOfSunLoungersWithTable);
        setEntryTickets(spots.numberOfEntryTickets);
    }, [spots]);

    const onSubmit = async () => {
        await updateSpotsRequest({
            numberOfEntryTickets: entryTickets < 0 ? 0 : entryTickets,
            numberOfMinipool: minipool < 0 ? 0 : minipool,
            numberOfSunLoungers: loungers < 0 ? 0 : loungers,
            numberOfSunLoungersWithTable: loungersWithTable < 0 ? 0 : loungersWithTable,
        })
    }

    const today = new Date();
    const todayString = today.toISOString();

    return (
        <Box className="dashboard-feature">
            <Card size='3' variant="classic" className="dashboard-feature__card">
                <Flex justify="between" direction="column">
                    <Text as="div" size="4" weight="bold">
                        Кількість послуг
                    </Text>
                    <Text as="div" size="1" color="gray">
                        Кількість доступних послуг на добу
                    </Text>

                    <Flex direction="column" mt="4" gap="2">
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Кількість паркомісць (вхідний квиток)
                            </Text>
                            <TextField.Root
                                placeholder="300"
                                value={entryTickets}
                                type="number"
                                onChange={(e) => setEntryTickets(parseInt(e.target.value))}
                            />
                        </label>
                        <FreeSpots service={Services.ENTRY_TICKET} date={todayString} />
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Шезлонги
                            </Text>
                            <TextField.Root
                                placeholder="100"
                                value={loungers}
                                type="number"
                                onChange={(e) => setLoungers(parseInt(e.target.value))}
                            />
                        </label>
                        <FreeSpots service={Services.SUN_LOUNGERS} date={todayString} />
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Шезлонги зі столиком
                            </Text>
                            <TextField.Root
                                placeholder="100"
                                value={loungersWithTable}
                                type="number"
                                onChange={(e) => setLoungersWithTable(parseInt(e.target.value))}
                            />
                        </label>
                        <FreeSpots service={Services.SUN_LOUNGERS_WITH_TABLE} date={todayString} />
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Шезлонги біля басейну
                            </Text>
                            <TextField.Root
                                placeholder="25"
                                value={minipool}
                                type="number"
                                onChange={(e) => setMinipool(parseInt(e.target.value))}
                            />
                        </label>
                        <FreeSpots service={Services.MINIPOOL} date={todayString} />
                        <Flex align="end" mt="4" direction="column">
                            <Flex gap="4" align="center">
                                <Button
                                    variant="ghost"
                                    onClick={() => navigate('/free-spots-view')}
                                >
                                    Місця по даті
                                </Button>
                                <Button
                                    onClick={onSubmit}
                                    disabled={isLoading || isUpdating}
                                >
                                    Зберегти
                                </Button>
                            </Flex>
                            {
                                isError && <Text size="1" color="red" mt="1">Помилка завантаження даних</Text>
                            }
                            {
                                isErrorUpdating && <Text size="1" color="red" mt="1">Помилка оновлення даних</Text>
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
        </Box>
    )
}
