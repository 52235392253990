import {PaginatedResponse} from "../../types/pagination";
import {Reservation, ReservationStatusString} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Services} from "../../types/services";

export interface ReservationsSlice extends Omit<PaginatedResponse<Reservation>, 'data' | 'total' | 'totalPages'> {
    status: ReservationStatusString | null,
    date: string | null,
    phone: string | null,
    reservationID: number | null,
    services: Services[],
}

const initialState: ReservationsSlice = {
    page: 1,
    perPage: 10,

    status: ReservationStatusString.InReview,
    date: null,
    phone: null,
    reservationID: null,
    services: [],
};

export const reservationsSlice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        setStatus: (state, action: PayloadAction<ReservationStatusString | null>) => {
            state.status = action.payload;
            state.page = 1;
        },
        setDate: (state, action: PayloadAction<string | null>) => {
            state.date = action.payload;
            state.page = 1;
        },
        setPhone: (state, action: PayloadAction<string | null>) => {
            state.phone = action.payload;
            state.page = 1;
        },
        setReservationID: (state, action: PayloadAction<number | null>) => {
            state.reservationID = action.payload;
            state.page = 1;
        },
        resetServices: (state) => {
            state.services = [];
            state.page = 1;
        },
        setService: (state, action: PayloadAction<{ service: Services, action: 'add' | 'remove'}>) => {
            state.page = 1;

            if (action.payload.action === "add") {
                state.services = [...state.services, action.payload.service];
                return;
            }

            // remove
            state.services = state.services.filter(s => s !== action.payload.service)
        },

    }
});

export const {
    setPage,
    setStatus,
    setDate,
    setReservationID,
    resetServices,
    setPhone,
    setService,
} = reservationsSlice.actions;

export default reservationsSlice;
