import React, {useEffect, useState} from "react";
import {Button, Dialog, Flex, Select, Text, TextField} from "@radix-ui/themes";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import {getServiceNameByEnum, getServiceType, Services, ServiceType} from "../../types/services";
import {servicesEnumKeysNumbers} from "../../helpers/utils";
import {useGetProfileQuery} from "../Profile/profileAPI";
import {Roles} from "../../types/roles";
import PaymentQR from "./components/PaymentQR";
import {BookingRequestPayload, Reservation, ReservationStatusString} from "./types";
import {getPriceAmount} from "../../helpers/prices";
import FreeSpots from "./components/FreeSpots";
import {useCreateBookingMutation} from "./reservationsAPI";

export default function CreatePayment() {
    const [reservation, setReservation] = useState<Reservation | null>(null);
    const [allowedServices, setAllowedServices] = useState<number[]>([]);
    const [calculatedPrice, setCalculatedPrice] = useState<number>(0);

    /* form fields */
    const [selectedService, setSelectedService] = useState('');
    const [amount, setAmount] = useState<number | ''>(0);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [numberOfSpots, setNumberOfSpots] = useState(1);
    const [userID, setUserID] = useState('');

    const {data: profile} = useGetProfileQuery(null);
    const [
        createBookingRequest,
        { isLoading: isCreating, isError: isCreateError }
    ] = useCreateBookingMutation();

    useEffect(() => {
        const price = getPriceAmount(
            parseInt(selectedService),
            numberOfSpots,
            dateFrom ? new Date(dateFrom) : undefined,
            dateTo ? new Date(dateTo) : undefined
        );

        setCalculatedPrice(price);
    }, [selectedService, numberOfSpots, dateFrom, dateTo]);

    useEffect(() => {
        if (! profile) return;

        let services = [];
        if (profile?.role === Roles.Admin) {
            services = servicesEnumKeysNumbers;
        } else {
            services = profile?.services || [];
        }

        setAllowedServices(services);

        if (services.length > 0) setSelectedService(services[0].toString());
    }, [profile]);

    if (allowedServices.length === 0) return null;

    const onCreate = async () => {
        if (selectedService === undefined || selectedService === null) return;

        const selService = parseInt(selectedService);

        if (! dateFrom) return;
        if (! amount) { // allow no amount if guest ticket
            if (
                selService === Services.GUEST_TICKET_ZONES_1 ||
                selService === Services.GUEST_TICKET_ZONES_1_2 ||
                selService === Services.GUEST_TICKET_ZONES_1_2_3
            ) {
                // do nothing;
            } else {
                return;
            }
        }

        let payload: BookingRequestPayload = {
            serviceId: selService,
            dateFrom,
            numberOfSpots,
            amount: amount ? amount : 0,
        }

        if (isHotelBooking || isGuestTicket) {
            payload.dateTo = dateTo;
        }

        if (userID) {
            payload.userID = parseInt(userID);
        }

        const response = await createBookingRequest(payload);

        // @ts-expect-error
        if (response.data) {
            setReservation({
                // @ts-expect-error
                id: response.data.reservationId,
                serviceId: parseInt(selectedService),
                amount: amount ? amount : 0,
                dateFrom,
                dateTo,
                promoCode: null, // todo: implement sending promocodes in the future
                createdAt: new Date().toISOString(), // stub
                numberOfSpots,
                status: ReservationStatusString.ReadyForPayment,
            })
        }
    }

    const isHotelBooking = getServiceType(parseInt(selectedService)) === ServiceType.ACCOMMODATION;
    const isGuestTicket =  parseInt(selectedService) === Services.GUEST_TICKET_ZONES_1 ||
        parseInt(selectedService) === Services.GUEST_TICKET_ZONES_1_2 ||
        parseInt(selectedService) === Services.GUEST_TICKET_ZONES_1_2_3;

    return (
        <Dialog.Root>
            <Dialog.Trigger>
                <Button>
                    <PlusCircleIcon width="20px" height="20px" />
                    Створити платіж
                </Button>
            </Dialog.Trigger>
            <Dialog.Content onOpenAutoFocus={(e) => e.preventDefault()} maxWidth="740px">
                <Dialog.Title>Створення платежу</Dialog.Title>

                {
                    reservation && <PaymentQR reservation={reservation} />
                }

                <Flex display={reservation ? 'none' : 'flex'} direction='column' gap='4'>
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            Послуга
                        </Text>
                        <Select.Root
                            value={selectedService}
                            onValueChange={(value) => setSelectedService(value)}
                        >
                            <Select.Trigger style={{ width: '100%' }} />
                            <Select.Content>
                                <Select.Group>
                                    {
                                        allowedServices.map((key) => (
                                            <Select.Item
                                                value={key.toString()}
                                                key={key}
                                            >
                                                { getServiceNameByEnum(key)}
                                            </Select.Item>
                                        ))
                                    }
                                </Select.Group>
                            </Select.Content>
                        </Select.Root>
                    </label>
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            Кількість
                        </Text>
                        <TextField.Root
                            type='number'
                            value={numberOfSpots}
                            min={1}
                            onChange={(e) => setNumberOfSpots(parseInt(e.target.value))}
                        />
                    </label>
                    {
                        profile?.role === Roles.Admin ? (
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    ID користувача (якщо відомо)
                                </Text>
                                <TextField.Root
                                    type='number'
                                    value={userID}
                                    onChange={(e) => setUserID(e.target.value)}
                                />
                            </label>
                        ) : null
                    }
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            { isHotelBooking ? 'Дата заїзду' : 'Час і дата'}
                        </Text>
                        <TextField.Root
                            type={ (isHotelBooking || isGuestTicket) ? 'date' : 'datetime-local'}
                            value={dateFrom}
                            min={
                                (isHotelBooking || isGuestTicket)
                                    ? new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":")).split('T')[0]
                                    : new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":"))
                            }
                            onChange={(e) => setDateFrom(e.target.value)}
                            step={(isHotelBooking || isGuestTicket) ? undefined : '15'}
                        />
                    </label>
                    {
                        (isHotelBooking || isGuestTicket) && (
                            <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                    Дата виїзду
                                </Text>
                                <TextField.Root
                                    type="date"
                                    value={dateTo}
                                    min={new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":")).split('T')[0]}
                                    onChange={(e) => setDateTo(e.target.value)}
                                    // step={isHotelBooking ? undefined : '15'}
                                />
                            </label>
                        )
                    }
                    <FreeSpots service={parseInt(selectedService)} date={dateFrom} />
                    <label>
                        <Text as="div" size="2" mb="1" weight="bold">
                            Сума
                        </Text>
                        <TextField.Root
                            type="number"
                            placeholder="XXX грн"
                            value={amount}
                            onChange={(event) => {
                                const amount = event.target.value ? parseInt(event.target.value) : ''
                                setAmount(amount);
                            }}
                        />
                        <Text size="1">Розрахована ціна: <b>{calculatedPrice}грн</b></Text>
                    </label>

                </Flex>

                <Flex gap="2" mt="6" justify="end" align="center">
                    {
                        reservation && (
                            <Button variant="ghost" color="gray" onClick={() => setReservation(null)}>
                                Очистити
                            </Button>
                        )
                    }
                    <Dialog.Close>
                        <Button variant="soft" color="gray">
                            Закрити
                        </Button>
                    </Dialog.Close>

                    {
                        ! reservation && <Button disabled={isCreating} onClick={onCreate}>Створити</Button>
                    }

                </Flex>
                <Flex justify="end" mt="1">
                    {
                        (isCreateError) && <Text size="1" color="red">Сталася помилка. Спробуйте знову</Text>
                    }
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    )
}
