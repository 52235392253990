import { emptySplitApi } from '../../api/api';
import {
    CreateOrUpdatePromoCodeRequest,
    PromoCode,
} from "./types";

export const promoCodesAPI = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getPromoCodesList: builder.query<PromoCode[], null>({
            query() {
                return {
                    url: '/promoCodes',
                };
            },
            providesTags: ['PromoCodesList'],
        }),
        getPromoCode: builder.mutation<PromoCode[], null>({
            query() {
                return {
                    url: '/promoCode',
                };
            },
        }),
        createOrUpdatePromoCode: builder.mutation<any, CreateOrUpdatePromoCodeRequest>({
            query(data) {
                const { createNew, ...request } = data;

                return {
                    url: '/promocode',
                    method: createNew ? 'POST' : 'PATCH',
                    body: request,
                };
            },
            invalidatesTags: () => ['PromoCodesList'],
        }),
    }),
});

export const {
    useGetPromoCodesListQuery,
    useCreateOrUpdatePromoCodeMutation,
} = promoCodesAPI;
